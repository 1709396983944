
export const itData = {
  mainTitle: "IT Career Mentor",
  accordionData: [
    {
      title: "About Job Specific IT Mentoring",
      description: [
        "In the Information Technology (IT) industry, innovation and evolution are constants. This industry includes a variety of job roles, from software development and cybersecurity to data analytics, AI and cloud computing. It plays an important role in shaping the future and driving global progress.",
        "The IT industry is all about agility and adaptability. Companies within the sector continue to push boundaries and strive to create more efficient systems, enhance connectivity, and revolutionise how businesses operate. The IT industry also influences communication, education, healthcare and commerce on a global scale.",
        "Moreover, the IT sector fosters a culture of continuous learning and collaboration. Professionals in this career are at the forefront of innovation, developing solutions to address emerging challenges. As technology becomes increasingly integrated into daily life, the IT industry's significance continues to grow.",
        "If you’re working in the IT sector, your growth opportunities are unparalleled. However, to navigate this complex career line, you’ll require more than just technical knowledge. Whether you’re looking to enter the IT industry or you want to climb the corporate ladder within the industry, you need strategic guidance and IT mentorship. ",
        "That’s where we come in! Our platform facilitates the connection between mentors and individuals like yourself who are hungry for insights and guidance. You can choose a mentor based on your current job role or the role you’re hoping to achieve.",
        "At Career Navig8r, our IT mentors have years of hands-on experience in this industry and can bring invaluable insights to the table. With their understanding of the challenges, trends and nuances of the IT industry, they can provide you with all the guidance you need. ",
        "This is your chance to gain expertise from those who have walked the same path that you’re on. Register with us and find your IT career mentor today! ",
      ]
    },
    {
      title: "Challenges Faced by Emerging IT Professionals",
      description: [
        "Starting a career in the IT industry comes with a range of challenges. One major obstacle is the rapid pace of technological advancements, which requires constant upskilling to stay in the game. The need for a broad skill set, covering coding languages, cybersecurity, and cloud computing, among others, also creates a stimulating yet overwhelming learning curve.",
        "Additionally, when you enter the IT workforce, you’ll need to face real-world scenarios that may not have been extensively covered in your academic settings. Navigating complex projects, collaborating in cross-functional teams, and maintaining tight deadlines are common challenges that will test the resilience and adaptability of your budding IT skills.",
        "Furthermore, the industry's competitive nature can be intense, with emerging professionals worldwide vying for job opportunities. Aside from technical expertise, getting a job in IT also requires effective communication skills and the ability to showcase your unique value in the industry.",
        "As you deal with these challenges, you could highly benefit from the guidance of a mentor in the IT industry. If you’re looking for someone who has been where you are and can teach you the ins and outs of this competitive industry, a job role-specific IT mentor is exactly what you need.",
        "Apply for our IT mentorship programme now!",
      ]
    },
    {
      title: "Who Needs an IT Career Mentor?",
      description: [
        "If you’re working in the IT industry, regardless of your experience level, an IT career mentor’s advice and guidance would be invaluable for your growth. ",
        "If you’re a new professional, an IT mentorship can help you gain insights into the intricacies of the field and industry trends, and provide you with practical advice on skill development. Your mentors in IT will guide you through early career challenges and help you transition from your academic settings to the dynamic and demanding IT workplace. ",
        "Similarly, if you’re a mid-career professional, IT mentors can help you navigate complex scenarios, refine your skill sets, and assist you in strategising to move forward in your career. With technology evolving, an IT mentor can be your trusted advisor, helping you keep track of the industry changes and adapt proactively. ",
        "Even as a seasoned IT expert, you can benefit from an IT mentorship programme, especially when exploring new specialities, transitioning to leadership roles, or seeking guidance on broader career trajectories. You could run your ideas by your mentor in the IT industry, learn from their experiences, and improve your strategic thinking to tackle your advanced challenges. ",
        "Ultimately, if you want to thrive in the IT industry, an IT career mentor's guidance is just what you need. The relationship between you and your mentor will foster professional development and knowledge transfer, and help you build a supportive network that will contribute to a resilient and successful IT career journey. ",
      ]
    },
    {
      title: "What To Expect from an IT Mentorship?",
      description: [
        "An IT mentorship can be beneficial for both newbies in IT and experienced professionals. Firstly, as a mentee, you can expect personalised guidance tailored to your specific job role, career goals and challenges.",
        "Your IT mentor, who will often be a seasoned industry expert, will share practical knowledge, industry trends, and firsthand experiences with you.They’ll provide you with invaluable insights that go beyond what formal education may have taught you.",
        "In an IT mentorship programme, you’ll get constructive feedback for skill development and performance enhancement.Mentors can offer a fresh perspective to help you identify your strengths and areas for improvement.The mentorship dynamic will also encourage open communication, creating a safe space for you to ask questions, seek advice, and discuss your career aspirations.",
        "Furthermore, mentorship isn’t just about technical skills, it will also help improve your soft skills such as effective communication, leadership and interpersonal dynamics. Your mentors in IT will guide you through the nuances of workplace culture and help you navigate challenges within the IT industry.",
        "Lastly, a successful IT mentorship can also help you build a professional network. You’ll gain access to the mentor's industry connections, which would potentially open doors to new opportunities, collaborations and a broader support system.",
        "In essence, with a mentor in the IT industry, you won’t just gain knowledge; you’ll also be able to grow personally and professionally.",
      ]
    },
    {
      title: "Find an IT Career Mentor at Career Navig8r Today!",
      description: [
        "When choosing a career or growing within it, it’s not uncommon to feel lost and apprehensive.Moreover, if you’ve just begun your career in a new industry, the path to success can seem long- winded and overwhelming. ",
        "You may have numerous questions such as what to expect, which skills are needed, and what employers are looking for when hiring.This is where you could benefit from having a qualified and experienced career mentor.They will help you overcome obstacles and grow in your career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r now ",
      ]
    },
  ]
};

export const hrData = {
  mainTitle: "HR Career Mentor",
  accordionData: [
    {
      title: "About Job-Specific HR Mentoring",
      description: [
        "The HR (Human Resources) industry is essential in modern organisations as they manage the workforce to drive productivity, engagement and organisational success. At its core, HR includes many and varied unique job roles and career paths including recruitment, talent management, employee relations, training and development, compensation and benefits, and HR technology.",
        "One of the key responsibilities of HR professionals is to attract and retain top talent by creating a positive work environment and offering competitive salary packages. They also handle the recruitment process, from sourcing candidates to conducting interviews and onboarding new hires.",
        "Moreover, HR professionals help create a culture of diversity, equality and inclusion within the workplace to ensure that all employees feel valued and respected.",
        "However, HR professionals often face the challenge of adapting to evolving workforce trends, such as remote work and the gig economy, while also navigating complex legal and regulatory requirements. This is where job-specific HR mentoring could help!",
        "At Career Navig8r, you can choose an HR mentor who will provide you with personalised guidance and support based on a particular aspect of your role or industry. This mentoring style focuses on the intricacies and nuances of specific job functions and can benefit you at any stage of your career in human resources.",
        "Your HR mentor will help you develop skills in a particular area, such as talent acquisition, performance management, employee relations, or HR technology implementation. The mentor in HR will use their own experience and expertise to offer insights, advice and practical strategies tailored to your unique needs and goals.",
        "This will not only accelerate your learning curve as an HR professional but also foster confidence and competence in handling job-specific responsibilities or navigating complex HR scenarios.",
        "If you’re an HR professional looking to enhance your skills, advance your career, and excel in your specialised areas of expertise, sign up with us to find your human resources mentor now!",
      ]
    },
    {
      title: "Challenges Faced by Emerging HR Professionals",
      description: [
        "Whether you’re a budding HR professional or hoping to climb higher up the corporate ladder, you’re likely to encounter a range of challenges as you navigate your career and strive to establish yourself within the industry.",
        "Firstly, if you don’t have extensive hands-on experience, you may struggle to address complex HR issues, handle employee relations matters effectively, or make strategic decisions that impact the organisation.",
        "Additionally, the HR industry is constantly evolving, with new technologies, methods and legal requirements emerging regularly. Keeping up with these changes requires continuous learning and professional development, which can be challenging if you’re new to the field.",
        "Moreover, establishing your credibility and earning the trust of colleagues and senior leaders can be an uphill battle. You have to build relationships, demonstrate competence, and effectively communicate HR initiatives and strategies to gain respect within your organisation.",
        "Furthermore, as an emerging HR professional, you may often find yourself juggling various tasks, from recruitment and onboarding to performance management and employee engagement initiatives, all while managing time constraints and deadlines.",
        "However, you can overcome these obstacles with the help of a mentor in human resources. They will provide learning opportunities, help you build strong networks, and keep you adaptable in the face of change.",
        "To grow and thrive in your HR career, join our HR mentorship programme now!",
      ]
    },
    {
      title: "Who Needs an HR Career Mentor?",
      description: [
        "If you’re just starting your career in human resources, you can greatly benefit from the guidance and support of a mentor. However, HR mentors aren’t only for newcomers to the field. Anyone pursuing a career in HR, regardless of their level of experience, can find value in having a human resources mentor.",
        "If you’re new to HR, you’ll need an HR mentor to navigate the complexities of the field, gain insights into industry best practices, and accelerate your learning curve. A mentor in human resources can provide invaluable advice on building foundational skills, understanding HR processes, and overcoming initial challenges.",
        "If you’re a mid-career HR professional, you can turn to HR mentors for guidance on advancing your career, transitioning into leadership roles, or specialising in specific areas such as talent management, organisational development, or HR technology. A mentor in HR can offer strategic career advice, share their own experiences, and help you find opportunities for growth and development.",
        "Even as a seasoned HR veteran, you could benefit from an HR mentorship programme, especially when you’re facing new challenges, transitioning to different industries or roles, or looking for fresh perspectives. A mentor in human resources can tell you about the challenges they have faced and the skills they have gained over the years, which would help you navigate complex HR issues with confidence.",
        "Overall, whether you’re just starting out or well-established in the field, you can benefit from the guidance, support and wisdom of a trusted mentor in HR. They have walked the same career path and have been exactly where you are. That makes them the ideal navigators to help you achieve your professional goals.",
      ]
    },
    {
      title: "What To Expect from an HR Mentorship Programme?",
      description: [
        "At Career Navig8r, our HR mentorship programme is designed to provide mentees with guidance, support and professional development opportunities within the field of human resources.",
        "When you sign up with us as a mentee, you can expect to receive personalised guidance from experienced HR professionals who are either one or multiple steps ahead of you in the HR industry. These human resources mentors will offer insights, advice and practical strategies tailored to your individual needs, career goals, and areas of interest within HR. That means you can choose a mentor based on the job role you’re hoping to achieve or excel in.",
        "An HR mentorship programme will give you the opportunity to engage in meaningful discussions, share experiences, and seek advice on navigating challenges commonly faced in HR roles. Your mentor in human resources will provide feedback on your work, help you develop new skills, and offer guidance on career advancement.",
        "Furthermore, through a mentorship program, you can connect with other professionals in the field, build relationships, and expand your professional networks. You’ll also gain exposure to different aspects of HR through shadowing experiences, project collaborations, or attending industry events.",
        "Sign up for our HR mentorship programme to receive all the support and guidance you need from experienced professionals in human resources.",
      ]
    },
    {
      title: "Find an HR Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re choosing a career or trying to grow and excel in it, it’s not uncommon to feel lost and apprehensive. Moreover, if you’ve just begun your career in a new industry, the path to success can seem long-winded and overwhelming.",
        "Regardless of the industry you’re in, you may have numerous questions such as what to expect, which skills are needed, and what employers are looking for when hiring. This is where you could benefit from having a qualified and experienced career mentor. They will be your navigators, helping you overcome obstacles and grow in your career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r now!",
      ]
    },
  ]
};

export const financeData = {
  mainTitle: "Finance Career Mentor",
  accordionData: [
    {
      title: "About Job-Specific Finance Mentoring",
      description: [
        "The finance industry encompasses a variety of institutions, services and activities that are essential for the management of money, assets and investments within an economy. From banking and investment firms to insurance companies and stock exchanges, the finance sector plays a major role in facilitating economic growth and stability.",
        "Finance professionals are skilled in managing financial resources, analysing data, and making strategic decisions to maximise profitability and mitigate risks. They work in various sectors of the finance industry, including banking, investment management, insurance, and corporate finance.",
        "These professionals often hold specialised qualifications such as Chartered Financial Analyst (CFA), Certified Public Accountant (CPA), or Chartered Financial Planner (CFP), which demonstrate their expertise and commitment to professional standards.",
        "If you’re a finance professional who advises on investment opportunities, creates financial strategies, or ensures regulatory compliance, you’ll need strong analytical skills and the ability to interpret complex financial data to make informed decisions. Moreover, you have to keep up with market trends, economic developments, and regulatory changes to effectively navigate the dynamic finance industry.",
        "This is where finance mentoring can help you! At Career Navig8r, you can choose a finance mentor based on a specific job role. This could be a role that you already have and want to excel in or a role that you’re aiming to get.",
        "Your chosen mentor in the financial industry will offer insights, advice and practical strategies tailored to your unique needs and goals. They’ll help you develop your skills while giving you the confidence to manage your responsibilities and navigate any complex financial issues that come your way.",
        "If you’re a finance professional looking to benefit from the experience and expertise of the best financial mentors, sign up with us now!",
      ]
    },
    {
      title: "Challenges Faced by Emerging Finance Professionals",
      description: [
        "As a budding finance professional, you’re likely to encounter various challenges as you navigate your career in the dynamic and competitive finance industry. One significant challenge is gaining practical experience and building a strong professional network.",
        "Today, even entry-level positions often require previous experience, which creates a rather tricky situation for recent graduates or career changers. Additionally, if you’re looking to break into certain specialised fields within finance, such as investment banking or hedge fund management, it can be particularly challenging due to the high competition and stringent hiring criteria.",
        "Moreover, you need to adapt to rapid technological advancements and evolving industry regulations. With various innovations and automation reshaping traditional finance practices, you have to continuously update your skills and knowledge to stay relevant.",
        "Another challenge is the pressure to perform in a high-stakes environment where financial decisions can have significant consequences. As an emerging professional, you may face intense workloads, tight deadlines, and demanding clients or supervisors, which can lead to stress and burnout.",
        "Furthermore, in the finance industry, long hours and unpredictable schedules are common. This can make it difficult to balance your professional ambitions with personal commitments while maintaining your physical and mental well-being.",
        "To navigate these obstacles, you’ll need resilience, adaptability, and a proactive approach to continuous learning and professional development. This can be achieved through finance mentoring.",
      ]
    },
    {
      title: "Who Needs a Finance Career Mentor?",
      description: [
        "Regardless of which stage you’re at in your finance career, you can benefit from having the best financial mentors to guide and support you.",
        "If you’re a recent graduate entering the finance industry, you can gain valuable insights and advice from finance mentors who have navigated similar career paths. These mentors can provide guidance on job search strategies, networking opportunities, and skill development to help you establish yourself in the field.",
        "If you’re a mid-career professional looking to advance or transition within the finance industry, you can also benefit from a finance mentorship programme. Mentors can offer guidance on career progression, leadership development, and navigating organisational dynamics. They can provide valuable perspectives and help you identify opportunities for growth and advancement.",
        "Even as an experienced finance professional, having a mentor can be helpful, especially if you’re facing new challenges or want to expand your expertise. A mentor in the financial industry can offer insights based on their extensive experience, provide strategic advice on complex financial matters, and help you navigate career transitions or leadership roles.",
        "Moreover, if you’re from an underrepresented or marginalised background in the finance industry, such as women or minorities, a finance mentor can provide support, advocacy and guidance to help you overcome barriers and achieve success.",
        "At Career Navig8r, our mentors from the financial industry have been exactly where you are and they’ve dealt with the same hurdles you’re experiencing. So, whether you’re seeking to grow, develop or excel in your finance career, the guidance, support and wisdom gained through finance mentoring can be invaluable for you.",
      ]
    },
    {
      title: "What To Expect from a Finance Mentorship Programme?",
      description: [
        "When you sign up for our finance mentorship programme, you’ll get to learn the ins and outs of your specific job role from experienced professionals within the finance industry.",
        "As a mentee, you can expect personalised guidance tailored to your career goals, interests and skill sets. Your mentor will provide individualised support, helping you navigate challenges and capitalise on opportunities specific to your career aspirations.",
        "This finance mentorship programme will also focus on skill development, allowing you to enhance your technical expertise, soft skills, and industry knowledge. Your mentor may provide training, recommend resources for self-study, and help you make better decisions in critical situations.",
        "Through mentorship, you’ll also get valuable networking opportunities, allowing you to connect with industry professionals, potential employers and peers. Your mentor may facilitate introductions, recommend networking events, or teach you how to build a professional network within the finance industry.",
        "Moreover, you can expect ongoing support as you advance in your career. Whether you’re aiming for promotions, job transitions, or skill development opportunities, your mentors will be right there to help you. They’ll offer advice on career planning, goal setting, and strategies for professional growth, and they’ll provide feedback to help you overcome challenges and achieve success in your finance career.",
        "Need help navigating the finance industry? Join our finance mentorship programme now!",
      ]
    },
    {
      title: "Find the Best Financial Mentors at Career Navig8r Today!",
      description: [
        "Whether you’re choosing a career or trying to grow and excel in it, it’s not uncommon to feel lost and apprehensive. Moreover, if you’ve just begun your career in a new industry, the path to success can seem long-winded and overwhelming.",
        "Regardless of the industry you’re in, you may have numerous questions such as what to expect, which skills are needed, and what employers are looking for when hiring. This is where you could benefit from having a qualified and experienced career mentor. They will be your navigators, helping you overcome obstacles and grow in your career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r now!"
      ]
    },
  ]
};

export const itManagerData = {
  mainTitle: "IT Manager Career Mentor",
  accordionData: [
    {
      title: "About IT Manager Mentoring",
      description: [
        "Have you just begun your career as an IT manager? Or have you been working in this role for a while and are looking to enhance your skills?",
        "At Career Navig8r, we offer IT manager training courses to guide IT managers like you in enhancing their leadership skills, technical knowledge, and strategic thinking.",
        "You’ll be able to choose experienced IT managers as your job-specific mentors, who’ll share their own personal insights and experience related to project management, team collaboration, and industry best practices. Your IT manager mentoring sessions will help you to improve your problem-solving, gain essential skills to advance in your career, and adapt to technological changes.",
        "Your IT manager mentor will provide personal feedback and use their own real-world experience to help you build confidence and competence. The result is that you’ll grow professionally and use your mentorship knowledge to contribute to a more skilled and efficient IT workforce.",
        "Want to excel in your chosen IT career path? Sign up for our IT management courses today.",
      ]
    },
    {
      title: "The Job Role of an IT Manager",
      description: [
        "The role of an IT manager is essential for the success of an organisation's technological infrastructure.",
        "Whether you’re aspiring to become an IT manager, you’ve recently achieved this position, or you’re fairly experienced but want to keep climbing the corporate ladder, you need to understand the full extent of what this career entails.",
        "In this role, you’ll be responsible for overseeing and coordinating your company’s IT department, ensuring that all systems, networks, and data are secure and operating efficiently. You’ll manage teams of IT professionals, delegate tasks, and provide guidance on complex technical issues. Additionally, you’ll need to plan and implement IT projects, set strategic goals, and use the appropriate technology to support your organisation’s business objectives.",
        "As an IT manager, you’ll also play an important role in budgeting, negotiating with vendors, and ensuring that the technology solutions used by your business are cost-effective. You’ll need to stay updated with the latest technological advancements and cybersecurity trends to protect your organisation from potential threats.",
        "It’s essential that you have great communication skills in this role, as you’ll need to liaise between technical teams and non-technical stakeholders. This will likely involve translating complex IT concepts into understandable terms.",
        "Ultimately, as an IT manager, your job is to ensure all technology is running properly within your organisation, allowing work to proceed without interruptions and keeping employees productive.",
      ]
    },
    {
      title: "Challenges Faced by IT Managers in the Industry",
      description: [
        "As an IT manager, you’re likely to face a myriad of challenges in your role, which are only complicated further by the rapidly evolving nature of technology and the increasing complexity of your company’s organisational needs.",
        "One of the primary challenges you’ll encounter is keeping up with the fast pace of technological advancements. You’ll need to continuously update your knowledge and skills to stay relevant and implement the latest technologies effectively.",
        "Cybersecurity threats are another big challenge. Cyberattacks and data breaches are a massive deal, and you’ll need to ensure that strong security measures are in place to protect sensitive information. This involves constant monitoring, updating your security protocols as and when necessary, and educating your company’s staff about best practices to mitigate risks.",
        "You might also face challenges due to budget constraints, as the advanced technology you need may exceed the financial limitations of your organisation. You’ll need to make strategic decisions to prioritise essential investments while making the most of your existing resources.",
        "Additionally, managing a diverse IT team is not an easy job. You’ll have to encourage your team to collaborate effectively, address skill gaps, and maintain high morale even when you’re facing tight deadlines and high-pressure situations. Effective communication is also required to bridge the gap between the technical and non-technical stakeholders within and outside of your organisation, ensuring that everyone understands the company's goals.",
        "Complying with regulations and standards is another constant challenge. You’ll need to stay on top of the ever-changing legal and industry-specific requirements, ensuring that your organisation remains compliant to avoid penalties and maintain trust.",
        "You’ll need to learn to patiently and expertly handle the challenges you’ll face as an IT manager. This is where you could benefit from the guidance of an IT manager mentor.",
        "Sign up for our IT service management courses now.",
      ]
    },
    {
      title: "Why do IT Managers Need Mentoring?",
      description: [
        "With IT manager mentoring, you’ll be able to work through the complexities of your role and become more effective in leading technology initiatives. Through our IT management course, you’ll receive valuable guidance and support from experienced professionals who can share their own insights and best practices based on their real-world experiences.",
        "This guidance is important for developing your strategic thinking and decision-making skills, which are essential for successfully managing IT projects.",
        "One of the key benefits of joining our IT manager training course is that it’ll give you the opportunity to learn how to handle the diverse challenges that come with your job role, with the support of experts who have dealt with the same issues. Whether you’re struggling with cybersecurity threats, budget constraints, or teamwork issues, they’ll share the most effective solutions based on their own experiences. ",
        "Your IT manager mentor will offer advice on how you can prioritise tasks, optimise your resources, and implement robust security measures. They’ll also help you improve your leadership and communication skills, so you can manage your team and interact with technical and non-technical stakeholders more effectively.",
        "Our IT manager mentoring helps you stay updated with the latest technological trends and industry standards. You probably don’t need us to tell you that this ongoing learning process is essential in the IT industry, where technology is constantly evolving.",
        "With our IT manager training, you’ll have a constant support system and a trusted mentor who will be happy to discuss the challenges you’re facing, explore solutions to your problems, and help you gain confidence in your abilities.",
        "If you’re looking to achieve your full potential as an IT manager and drive organisational success, now’s the time to sign up for our IT service management course.",
      ]
    },
    {
      title: "What to Expect from IT Manager Training Online?",
      description: [
        "At Career Navig8r, our online IT manager training course can give you a learning experience like no other. It’s designed to equip professionals like you with all the essential skills and knowledge to excel as an IT manager.",
        "During our IT management course, your chosen IT manager mentor will cover a wide range of topics, including project management, cybersecurity, team leadership, and strategic planning. They may also conduct assessments and quizzes to test your knowledge and track your progress.",
        "Through this IT service management course, you’ll improve your skills in using various IT tools and software and build on your understanding of industry best practices and emerging technologies. Your IT manager mentor will teach you the ins and outs of IT management and train you on how to use your knowledge in real-world situations. They’ll draw from their own experiences to help you improve your problem-solving and decision-making skills.",
        "Our IT manager mentoring programme may also give you access to networking opportunities. You’ll be able to interact with and learn from others in your industry, enhancing your career prospects.",
      ]
    },
    {
      title: "Find an IT Manager Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const marketingManagerData = {
  mainTitle: "Marketing Manager Career Mentor",
  accordionData: [
    {
      title: "About Marketing Manager Mentoring",
      description: [
        "Have you just started your new job as a marketing manager and want to learn how to excel in the position? Or are you looking to become a marketing manager but need tips on how to improve your skills in this specific role?",
        "At Career Navig8r, our structured marketing manager mentoring programme is designed to guide aspiring or junior marketers like you towards professional growth. Through one-on-one sessions online, you’ll learn the ins and outs of the industry from some of the best marketing manager mentors.",
        "Your mentor will offer insights on strategy development, campaign management, and leadership skills. This marketing manager training course will also help you handle challenges like brand positioning, data-driven decision-making, and market research. Additionally, you’ll receive practical advice, feedback, and hands-on learning to build your confidence and expertise.",
        "By sharing real-world experiences and industry trends, your marketing manager mentor will help you enhance your creative and analytical abilities. They’ll prepare you for leadership roles while allowing you to learn more about the marketing industry.",
        "Ready to advance in this specific job role? Sign up for Career Navig8r’s marketing manager course today.",
      ]
    },
    {
      title: "The Job Role of a Marketing Manager",
      description: [
        "To be an excellent marketing manager, you need to have a good understanding of your duties and purpose in this role.",
        "As a marketing manager, it’s your job to help your company grow by developing and executing strategic marketing initiatives. Your responsibilities will include market research, identifying target audiences, and creating compelling campaigns that align with business goals.",
        "You’ll oversee brand management and ensure that your company’s messaging is consistent across all platforms, from digital to traditional media. You’ll also collaborate with cross-functional teams, including sales, product development, and creative departments, to create cohesive marketing strategies.",
        "Aside from campaign management, you’ll handle budgeting for all marketing projects, analyse the effectiveness of your marketing efforts, and adjust your strategies based on performance data. ",
        "A key part of your role involves keeping up with industry trends and competitor activities to maintain a competitive edge. You’ll also need to focus on building customer relationships and brand loyalty and encouraging customer engagement.",
        "With a balance of creative vision and analytical thinking, you’ll build brand awareness and help your company to increase its revenue and succeed in the long run.",
      ]
    },
    {
      title: "Challenges Faced by Marketing Managers in the Industry",
      description: [
        "When you’re working as a marketing manager, you’re likely to come across some unique challenges that you’ve probably never faced before.",
        "One of the biggest hurdles is staying updated with rapidly changing digital trends, such as social media algorithms and emerging platforms. You’ll need to constantly adapt to these changes and make quick decisions so your campaigns remain effective.",
        "Another challenge is handling data overload. With access to vast amounts of customer data, you’ll need to sift through analytics to extract valuable insights while avoiding analysis paralysis. It’s not easy to balance creativity with data-driven strategies.",
        "Budget constraints can also cause issues, and you’ll often be asked to do more with less, optimising your campaigns for maximum return on investment. Additionally, competition is fierce, which means you have to innovate to stand out and engage customers effectively.",
        "You’ll also face internal pressures, such as aligning your marketing strategies with other departments’ goals and managing team dynamics. All of this can further complicate your role.",
        "To overcome these challenges, you’ll need to be adaptable and creative and have strategic foresight. This is where you could benefit from the guidance of a marketing manager mentor who has faced similar issues and can steer you in the right direction.",
        "Your mentor will share their own experiences of tackling industry-specific obstacles. They can give you practical advice on managing high-pressure situations and making better campaign decisions.",
        "Through our marketing manager training course, you’ll gain a stronger understanding of modern marketing and be able to grow into a more confident, resourceful leader within your organisation.",
      ]
    },
    {
      title: "What to Expect from Marketing Manager Training Online?",
      description: [
        "Career Navig8r’s online marketing manager mentoring programme offers a convenient and personalised approach to professional development.",
        "Through our virtual sessions, you’ll receive tailored guidance from an experienced marketing manager mentor who was once exactly where you are now in your career. They’ll teach you all about campaign management, brand development, and data analysis, helping you refine your creative and analytical abilities.",
        "Our marketing manager course allows for flexible scheduling, making it easier to fit around your work commitments. You’ll also get regular feedback, constructive critiques of your work, and real-world advice on handling common challenges, such as budget limitations, cross-departmental collaboration, and adapting to digital marketing changes.",
        "Another advantage of our marketing manager training course is that you get access to a diverse pool of mentors, allowing you to learn from and network with professionals across different industries and global markets.",
        "Overall, our course will help you develop leadership capabilities, stay competitive, and build confidence to excel in your marketing career. Sign up for our marketing manager course today.",
      ]
    },
    {
      title: "Find a Marketing Manager Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const complianceManagerData = {
  mainTitle: "Compliance Manager Career Mentor",
  accordionData: [
    {
      title: "About Compliance Manager Mentoring",
      description: [
        "Have you recently been promoted to become a compliance manager? Or have you worked in this role for a while and are looking to enhance your skills and advance up the corporate ladder? Or are you simply looking for tips on how to be a compliance manager?",
        "At Career Navig8r, we’ve designed a compliance manager mentoring programme that helps develop the skills and knowledge of compliance professionals. It’ll teach you how to navigate complex regulatory environments effectively.",
        "This compliance manager training course will pair you with an experienced compliance manager mentor who’ll offer guidance and personalised advice on risk management, legal frameworks, and organisational policy enforcement. Through one-on-one sessions, you’ll gain insights into best practices, learn to address ethical dilemmas, and develop critical thinking skills for making better decisions.",
        "Our compliance manager training will also help you grow as a leader, preparing you to take on greater responsibilities within your organisation while ensuring that you follow the laws and regulations. It’ll ultimately encourage you to become more accountable and maintain integrity.",
        "Ready to find out how to be a compliance manager? Sign up for our compliance manager course today.",
      ]
    },
    {
      title: "The Job Role of a Compliance Manager",
      description: [
        "To learn how to be a compliance manager, you need to understand what this role entails.",
        "As a compliance manager, you’ll be responsible for ensuring that your organisation follows all legal regulations, industry standards, and internal policies. You’re responsible for developing, implementing, and overseeing programmes that prevent illegal, unethical, or improper conduct.",
        "Your key duties will include conducting risk assessments, designing compliance audits, and reviewing business practices to ensure they meet regulatory requirements. You’ll also work closely with different departments to educate employees about compliance issues and ensure that all company policies are clearly understood and followed.",
        "Additionally, you’ll need to stay updated on new laws and regulations that could affect your organisation, and keep adjusting the internal policies and procedures as necessary. You’ll also have to investigate any incidents of non-compliance, recommend corrective actions, and put safety measures in place to prevent future violations.",
        "Your role is essential in maintaining your company’s integrity and protecting its reputation, as well as preventing financial or legal risks. That’s why strong analytical, communication, and leadership skills are needed to succeed in this position.",
      ]
    },
    {
      title: "Challenges Faced by Compliance Managers in the Industry",
      description: [
        "As a compliance manager, you’re likely to face numerous challenges as you work to ensure your organisation follows the necessary standards and policies.",
        "One major hurdle is keeping up with the frequently changing laws, industry standards, and government regulations. You’ll need to constantly monitor these changes and update your company policies as and when needed.",
        "Another challenge is getting to grips with global compliance, especially if your organisation is operating in multiple jurisdictions with differing regulations. You’ll have to ensure consistent compliance across all regions, which can be complex and resource-intensive.",
        "You may also find it difficult to encourage compliance within your organisation, due to resistance from employees or leadership, who view it as a burden. This can hinder the effective implementation of the policies.",
        "To tackle these challenges, you’ll need to be adaptable and vigilant, and have strong problem-solving skills. This is where you could learn from the guidance and advice of a compliance manager mentor who has dealt with these issues in their career.",
      ]
    },
    {
      title: "Why do Compliance Managers Need Mentoring?",
      description: [
        "You can benefit greatly from compliance manager mentoring due to the complex and evolving nature of your job role. Through our compliance manager training course, you’ll receive guidance from professionals who have faced similar challenges and can offer valuable insights on best practices.",
        "As laws and regulations continue to change, your compliance manager mentor will help you stay informed about new compliance requirements and adapt your strategies accordingly. They’ll help you enhance your decision-making skills by providing real-world examples of handling difficult situations, such as ethical dilemmas or regulatory audits.",
        "Through compliance manager training, you’ll get advice on managing cross-functional teams and building a culture of compliance within your organisation. This can be particularly challenging if you lack experience.",
        "Additionally, our compliance manager course will help you develop leadership qualities. As your role will often require influencing organisational behaviour, strong leadership is essential.",
        "By learning from experienced compliance manager mentors, you can build the confidence needed to address resistance, handle non-compliance incidents, and make key decisions that protect your company’s reputation and legal standing.",
        "Sign up for Career Navig8r’s compliance manager course today.",
      ]
    },
    {
      title: "What to Expect from Compliance Manager Training Online?",
      description: [
        "Career Navig8r’s compliance manager mentoring programme online will give you flexible, personalised guidance tailored to the unique challenges you face in this specific job role.",
        "As a mentee, you can expect one-on-one sessions with experienced compliance manager mentors. They’ll provide expert advice on working in complex regulatory environments and staying updated on industry changes, as well as how to build effective compliance strategies.",
        "Through our virtual platform, you can access mentorship from anywhere and at any time, offering a convenient solution that doesn’t clash with your demanding schedule. The online format allows for a wide range of learning opportunities, including discussions on real-world compliance scenarios. ",
        "You’ll receive practical insights on risk management, conducting compliance audits, and handling ethical dilemmas. You’ll also develop your leadership skills and be able to tap into more networking opportunities, which can help advance your career.",
        "Through our compliance manager training course, you’ll receive regular feedback to ensure that you keep improving and adapting to the evolving regulations.",
      ]
    },
    {
      title: "Find a Compliance Manager Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const dataScientistData = {
  mainTitle: "Data Scientist Career Mentor",
  accordionData: [
    {
      title: "About Data Scientist Mentoring",
      description: [
        "Are you wondering how to become a data scientist and excel at the job role? Or are you already a data scientist looking to expand your skill set and advance your career?",
        "At Career Navig8r, we’ve created a comprehensive data scientist training course that can give you personalised guidance. If you’re an aspiring or current data professional, this data scientist training mentoring programme will help you get to grips with the complexities of data science.",
        "You can select your own data scientist training mentor, benefiting from their industry expertise and insights on essential skills like machine learning, data analysis, and coding best practices. Through one-on-one online sessions, your mentor will offer career advice, project feedback, and technical assistance, ensuring that you stay on track with your learning goals.",
        "This data scientist training programme will create a supportive environment, address real-world challenges, and help you build confidence in your abilities. Whether you're new to data science or looking to advance in your existing role, mentorship can accelerate your journey towards success.",
        "Sign up for our data scientist training course today.",
      ]
    },
    {
      title: "The Job Role of a Data Scientist",
      description: [
        "As a data scientist, your job is to extract valuable insights from complex datasets to inform business decisions. You’ll need to collect, clean, and organise large volumes of data, using tools like Python, R, and SQL for analysis.",
        "By applying statistical techniques, machine learning algorithms, and data visualisation tools, you’ll need to uncover patterns, trends, and correlations that can help solve business challenges or optimise operations.",
        "You’ll often collaborate with cross-functional teams, including data engineers, business analysts, and stakeholders, to get a thorough understanding of the problem at hand and offer actionable insights. Your role may also involve creating predictive models, forecasting outcomes, and developing data-driven strategies to enhance product development, marketing, customer experience, or risk management.",
        "To excel at this job role, you’ll need strong problem-solving abilities, critical thinking, and a good understanding of data architecture. As businesses become more data-driven, your ability to translate raw data into meaningful conclusions that lead to impactful decisions will be an indispensable asset to any organisation.",
      ]
    },
    {
      title: "Challenges Faced by Data Scientists in the Industry",
      description: [
        "As a data scientist, you’ll likely face many challenges in the industry, especially as you climb the corporate ladder.",
        "A key issue is ensuring data quality and availability. Sometimes, the data you work with may be incomplete, inconsistent, or unstructured, which means you’ll need to clean it up before analysis can even begin. This process can be time-consuming and may affect the accuracy of your model.",
        "Another challenge is managing large volumes of data. As your business generates more data, handling and processing it efficiently can become difficult, especially when you’re dealing with big data infrastructure and determining feasible scalability solutions.",
        "Aligning your data-driven insights with your company’s goals can also be tricky. Aside from having technical expertise, you should also be able to understand the business context to ensure your findings are actionable and relevant. Miscommunication between technical teams and non-technical stakeholders can further complicate this.",
        "Keeping up with rapidly evolving technology is consistently challenging in your role. With constant advancements in machine learning algorithms, tools, and programming languages, you need to keep learning to stay competitive and deliver the best results.",
        "Dealing with these challenges can be extremely stressful, especially if you’re doing it alone. This is where data scientist training mentoring will benefit you.",
        "Through our data scientist training course, you’ll have the opportunity to enhance your skills with guidance from an experienced professional who has achieved great success in the same job role and has more than likely faced similar issues. Based on their own experiences, they can help you select the right tools and techniques for solving real-world problems.",
        "Your data scientist training mentor can offer personalised advice, helping you understand industry standards and avoid common pitfalls, ensuring you stay focused on relevant trends. Additionally, they can build your confidence by offering feedback on projects, assisting in code optimisation, and sharing best practices for handling complex data.",
        "Join our data scientist training course today.",
      ]
    },
    {
      title: "What to Expect from Data Scientist Training Online?",
      description: [
        "At Career Navig8r, our online data scientist training mentoring programme will help you learn and grow professionally in a flexible and personalised setting.",
        "You can attend one-on-one sessions with experts in the industry who’ll give you tailored guidance on various aspects of data science, including machine learning, data visualisation, statistical modelling, and coding in languages like Python and R. ",
        "Your data scientist training mentor will help you build a strong foundation by working with you on real-world projects, offering feedback, and sharing industry best practices. Whether you're new to the field or looking to advance your skills, our data scientist training course will teach you how to tackle challenges like data cleaning, handling large datasets, and applying algorithms effectively.",
        "Your mentor will also offer career advice, from resume building and interview preparation to networking within the industry. This can be invaluable for your future in this field.",
        "The online nature of our data scientist training mentoring allows for flexibility in your scheduling, making it easier to fit into your busy routine. You can expect a supportive, collaborative environment that helps accelerate your learning and boosts your confidence in handling an array of complex data problems.",
      ]
    },
    {
      title: "Find a Data Scientist Training Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const facilitiesManagerData = {
  mainTitle: "Facilities Manager Career Mentor",
  accordionData: [
    {
      title: "About Facilities Manager Mentoring",
      description: [
        "Are you aiming to become a facilities manager and want to learn more about the role? Or have you recently started working in this job role and need expertise on how to excel in your career?",
        "At Career Navig8r, we’ve designed a facilities manager mentoring programme to support and develop emerging professionals like you in this industry. You’ll be paired with experienced mentors who will help you improve your job skills, tackle common challenges in your role, and advance your career in facility operations, maintenance, and project management.",
        "Your chosen facilities manager mentor will guide you on best practices, leadership, and strategic planning, drawing from their personal experience. Our facilities manager training course will help you share and gain knowledge and enhance your problem-solving abilities, as well as build your confidence—all of which can benefit you and your organisation.",
        "Overall, facilities manager mentoring is ideal for anyone looking to grow in this field. If you’re ready to take your career to the next level, sign up for our facilities manager course today.",
      ]
    },
    {
      title: "The Job Role of a Facilities Manager",
      description: [
        "As a facilities manager, your main job is to ensure the smooth operation and maintenance of your organisation's physical environment. You’ll be responsible for overseeing building maintenance, safety, and services, including HVAC systems, plumbing, electrical setups, security, and cleaning.",
        "You need to ensure that the facility is safe, efficient, and compliant with relevant regulations and standards. Your duties will also include managing budgets, coordinating with contractors and service providers, and implementing cost-saving initiatives.",
        "Space management, procurement of supplies, and workplace design may also fall under your job description. Another duty you may be tasked with is emergency preparedness, ensuring that systems are in place for quick response to unforeseen situations like power outages or safety breaches.",
        "Strong communication and problem-solving skills are essential in this role, as you’ll often need to work with various departments to ensure efficiency in all operations. Your role is integral to creating a comfortable, functional work environment that supports productivity and business continuity.",
      ]
    },
    {
      title: "Challenges Faced by Facilities Managers in the Industry",
      description: [
        "In your job role as a facilities manager, you’re bound to face numerous challenges as the industry continues to evolve.",
        "One key challenge is maintaining an ageing infrastructure. This is where you’ll need to balance repairs with ongoing operations, which can be complex and costly. Budget constraints can add further pressure to this issue, as you’ll have to ensure that you don't cut corners on necessary costs.",
        "Regulatory compliance is another hurdle, requiring you to stay up-to-date with health, safety, and environmental regulations, which vary by location and industry. Additionally, as energy efficiency is increasingly prioritised these days, you’ll need to come up with innovative strategies to reduce consumption while maintaining comfort and functionality.",
        "Managing vendor relationships and service contracts can also be challenging, particularly when you’re negotiating to receive cost-effective yet high-quality solutions. You may also have to deal with unexpected emergencies, like equipment failures or natural disasters, which require quick, effective responses, putting pressure on your problem-solving abilities.",
        "As workplaces evolve, you’ll need to adapt to changing technologies, such as smart building systems. You’ll have to integrate them into daily operations and ensure that the new systems enhance rather than disrupt business activities.",
        "This role demands a diverse skill set, ranging from technical expertise to leadership. That’s where our facilities manager training course comes in.",
        "Our facilities manager mentoring programme will give you a chance to learn from a professional who has years of experience in the same job role and has tackled similar challenges. Your mentor can provide insights into industry best practices, innovative solutions for complex problems, and effective ways to manage your teams and projects.",
        "This guidance will help you identify areas for improvement, set realistic goals, and build confidence in your abilities. With a mentor, you’ll be better equipped to overcome these challenges while growing personally and professionally.",
        "Sign up for our facilities manager course today.",
      ]
    },
    {
      title: "What to Expect from Facilities Manager Training Online?",
      description: [
        "At Career Navig8r, our facilities manager mentoring programme online offers a flexible, convenient way for you to gain valuable guidance and industry insights.",
        "Once you sign up, you can expect personalised one-on-one sessions with experienced mentors, tailored to your unique career goals and challenges. In our virtual meetings, you’ll receive practical advice on problem-solving, leadership development, and operational strategies in facilities management.",
        "Our facilities manager training course will give you a safe and supportive space to gain knowledge, ask questions, and discuss your doubts and concerns regarding your industry and job role. The online format also allows you to pick the best time for these sessions, ensuring that your daily schedule isn’t disrupted.",
        "Additionally, you’ll have access to a wider pool of facilities manager mentors from different regions and sectors. This broader perspective can help to diversify your insights and learn from other professionals on how they solve problems creatively. You’ll also be able to tap into beneficial networking opportunities.",
        "Ultimately, our online facilities manager course will give you the knowledge, skills, and confidence to excel in your role, no matter the size or complexity of the facilities you manage.",
      ]
    },
    {
      title: "Find a Facilities Manager Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const customerServiceRepresentativeData = {
  mainTitle: "Customer Service Representative Career Mentor",
  accordionData: [
    {
      title: "About Customer Service Mentoring",
      description: [
        "Are you a newly promoted customer service representative, or do you want to learn how to be a customer service representative? Or, have you been working in this role for a while and want to improve your skills?",
        "Career Navig8r’s customer service mentoring programme is designed to help new or less experienced customer reps like you excel in their job roles. Through one-on-one guidance from our seasoned customer service mentors, you’ll develop essential skills for your role, such as active listening, effective communication, problem-solving, and empathy.",
        "Our customer service training course offers a supportive environment for learning the nuances of customer interactions, handling difficult situations, and improving your performance. It’ll also help you grow personally and professionally and enhance your job satisfaction, keeping you motivated by offering continuous feedback and encouragement.",
        "With proper customer service training, you’ll become more confident, capable, and customer-focused. You’ll learn to provide exceptional service for your clients and ensure long-term success in your company.",
        "Want the best tips and tricks on how to work in customer service? Sign up for our customer service training course today.",
      ]
    },
    {
      title: "The Job Role of a Customer Service Representative",
      description: [
        "Wondering how to get a customer service role? First, you need to understand what skills and responsibilities this job demands.",
        "As a customer service representative, you’re responsible for maintaining positive relationships between your company and its customers. You’ll be tasked with handling customer inquiries, complaints, and issues, and resolving them in a timely and satisfactory manner.",
        "This job often involves answering phone calls, responding to emails or live chats, and managing social media queries. You should have strong communication and problem-solving skills, as you’ll need to understand customers' needs and provide appropriate solutions.",
        "You’ll often work with multiple departments to resolve issues related to orders, billing, technical support, or product information. You’ll also be responsible for maintaining accurate records of customer interactions and ensuring that customer satisfaction remains high.",
        "To do well in this role, empathy and patience are essential, especially when you’re dealing with complicated situations or angry customers. You’ll be acting as the voice of the company, ensuring smooth communication and building trust, which will encourage customer loyalty and lead to long-term business success.",
      ]
    },
    {
      title: "Challenges Faced by Customer Service Representatives in the Industry",
      description: [
        "When you’re working as a customer service representative, you’re likely to face some common challenges as you try to balance customer satisfaction with your company’s policies.",
        "One of the main challenges is dealing with difficult or frustrated customers. To handle harsh complaints, negative feedback, and emotional responses, you need to be patient and empathetic and have strong problem-solving skills.",
        "You’ll also face customers who have high expectations for quick and effective solutions. You’ll often find yourself under pressure to resolve issues in real time across multiple communication channels, such as over the phone, by email, or through chat.",
        "Inadequate resources or insufficient training can also make it harder to address complex queries. This can lead to frustration for you and the customer.",
        "Additionally, burnout and stress are common, especially in fast-paced environments with high call volumes. You’ll need to find a work-life balance and learn to handle repetitive tasks, constant interaction, and sometimes unrealistic performance targets, which can lead to mental fatigue.",
        "To tackle these challenges, you’ll need strong interpersonal skills and resilience. You need to be able to adapt to the evolving demands of customer service, which can be easier with the expert guidance of a customer service mentor.",
        "Want to learn how to be a customer service representative? Find your mentor with our customer service training course.",
      ]
    },
    {
      title: "Why do Customer Service Representatives Need Mentoring?",
      description: [
        "Want to learn how to get a customer service role and excel in it? Our customer service mentoring programme can help enhance your skills, confidence, and job performance in this demanding role.",
        "In our customer service training, you’ll get personalised guidance from experienced professionals, who can teach you how to deal with complex customer interactions and improve your problem-solving abilities. ",
        "You’ll gain a deeper understanding of company policies, customer expectations, and communication techniques, which are essential for delivering effective and empathetic support.",
        "Your chosen customer service mentor can also help you manage stress and avoid burnout by offering emotional support and practical advice on how to handle difficult customers or high-pressure situations. When you receive regular feedback and coaching, you can continuously improve your performance, which should boost your job satisfaction and keep you motivated.",
        "Our customer service training course can help you move towards promotions and senior job roles in your career by nurturing your leadership skills and fostering professional growth. It’ll give you a supportive environment where you feel empowered to ask questions, share your concerns, and refine your approach to customer service.",
        "If you want to learn how to work in customer service and become more competent and confident in your job, customer service training can benefit you and your organisation.",
      ]
    },
    {
      title: "What to Expect from Customer Service Training Online?",
      description: [

        "At Career Navig8r, our customer service mentoring online gives you a flexible, accessible way to enhance your skills and gain confidence. If you’re wondering how to be a customer service representative or how to get a customer service role, this course is for you.",
        "In our virtual sessions, you can expect personalised guidance from an experienced customer service mentor who’ll provide valuable insights into effective communication, conflict resolution, and problem-solving strategies. These sessions will often include exercises, assessments, feedback on customer interactions, and tips on handling difficult situations.",
        "Our online customer service training allows you to learn at your own pace, anytime and anywhere. Your mentor will connect with you via video calls, chat platforms, and emails to provide ongoing support and address any questions or concerns in real-time.",
        "You can expect to gain practical knowledge on using digital customer service platforms and managing various communication channels, such as email, live chat, and social media. You’ll also get to network with other professionals, which can help you take your career to the next level.",
        "The convenience of our flexible online customer service training means you can balance learning with your daily responsibilities, making it an effective solution for continuously improving your customer service skills.",
      ]
    },
    {
      title: "Find a Customer Service Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const accountingClerkData = {
  mainTitle: "Accounting Clerk Career Mentor",
  accordionData: [
    {
      title: "About Accounting Clerk Mentoring",
      description: [
        "Have you just entered the accounts and finance industry as an accounting clerk? Are you looking to enhance your skills in this job role and climb the corporate ladder?",
        "Career Navig8r offers an accounting clerk mentor programme that guides entry-level employees like you to develop their skills and advance in their careers. Your chosen accounting clerk career mentor, usually an experienced accountant or senior clerk, will provide insights into the best practices, industry standards, and professional ethics in this career.",
        "They’ll help you understand complex financial procedures and improve your accuracy in bookkeeping, and teach you how to become proficient in various accounting software. Mentoring with Career Navig8r also gives you access to regular feedback and performance reviews.",
        "Your accounting clerk mentor will offer career advice and help you set and achieve your professional goals. This relationship will give you the support you need to confidently and competently handle accounting tasks.",
        "If you’re looking to rise through the ranks in your accounting job role, sign up with us to find the best accounting clerk career coach today.",
      ]
    },
    {
      title: "The Job Role of an Accounting Clerk",
      description: [
        "To excel as an accounting clerk, you need to have a good understanding of your tasks and duties in this job role.",
        "As an accounting clerk, you’ll play an important role in the financial operations of your organisation. Your primary responsibilities will include maintaining financial records, processing transactions, and ensuring accuracy in financial documentation. You’ll handle tasks such as data entry, updating ledgers, and reconciling bank statements.",
        "You’ll also be responsible for managing accounts payable and receivable, preparing invoices, and processing payments. Additionally, you’ll assist in payroll processing by calculating and verifying employee time and attendance records.",
        "Your duty as an accounting clerk is to support the accounting team by providing necessary documentation for audits and preparing financial reports. You’ll also need to ensure compliance with company policies and financial regulations.",
        "To be successful in this role, you’ll need to have exceptional attention to detail and strong organisational skills to maintain accuracy and prevent errors. You’ll also need to be proficient in accounting software and have at least basic mathematical skills.",
        "Overall, your job is to ensure the smooth and efficient operation of the accounting department, contributing to the financial health and stability of your organisation.",
      ]
    },
    {
      title: "Challenges Faced by Accounting Clerks in the Industry",
      description: [
        "As an accounting clerk, you’re bound to encounter a variety of challenges in your role, which could impact your efficiency and job satisfaction.",
        "One major challenge is keeping up with the high volume of transactions you’ll need to process daily. Keeping on top of your heavy workload means you’re more likely to make errors, particularly when coupled with tight deadlines. This is where maintaining accuracy is key, as even small mistakes can have substantial financial repercussions.",
        "Another challenge is staying updated with the constantly evolving software and technologies available to accountants today. As your company adopts new systems to enhance efficiency, you’ll need to continuously learn and adapt, which you may find time-consuming and stressful.",
        "Additionally, you’ll have to get to grips with complex regulatory requirements and compliance standards, which vary from one industry to the next. Keeping up with these regulations is essential to avoid legal issues and ensure that all your financial reports are accurate.",
        "You’ll also often face pressure to perform under strict deadlines, especially during financial closing periods or audits. This pressure can lead to burnout and affect your job satisfaction.",
        "Communication is another hurdle, as you’ll need to liaise with various departments and external stakeholders. If you make any communication errors, you might end up with delayed payments or inaccuracies in financial records.",
        "Another common challenge in an accounting clerk’s job role is managing the monotony that may arise from the repetitive nature of your accounting tasks, leading you to feel disengaged with your job role. It can be difficult to find motivation and maintain focus in your working environment.",
        "Despite these obstacles, as an accounting clerk, your role is key to ensuring the financial integrity and stability of your organisation. Being willing to persevere and adapt in your career is essential for your success.",
      ]
    },
    {
      title: "Why do Accounting Clerks Need Mentoring?",
      description: [

        "Career Navig8r’s accounting clerk mentor programme can significantly enhance your professional growth and job performance.",
        "If you’re a newcomer to the industry, you’re likely to face a steep learning curve. Our mentorship course will provide you with the guidance and support you need to build on your understanding of complex financial systems and procedures.",
        "You’ll be able to choose an accounting clerk career mentor who has been exactly where you are now. As they’ve dealt with the same issues, they can offer their own practical insights and share best practices that may not have been covered in your formal training.",
        "Our accounting clerk mentoring will help you develop a deeper understanding of your role, and you’ll pick up new skills for mastering accounting software and interpreting financial data accurately. You’ll receive regular feedback from your mentor, helping you to identify and correct mistakes and improving your accuracy and efficiency.",
        "Your accounting clerk career coach will also ensure you’re up-to-date with the latest regulatory requirements and industry standards, reducing your risk of errors. They’ll also help you develop your soft skills, such as effective communication and time management, which are important for interacting with other departments and meeting deadlines.",
        "Your job-specific mentor will provide emotional support, helping you manage the pressures of high-volume workloads and tight deadlines. This will build your confidence and prevent you from feeling isolated in your role, helping you to feel more satisfied with your career. ",
        "Overall, our accounting clerk career mentorship course will equip you with the skills, knowledge, and resilience needed to thrive in your role and advance in your career.",
      ]
    },
    {
      title: "What to Expect from an Accounting Clerk Mentor Programme Online?",
      description: [
        "At Career Navig8r, our online accounting clerk mentor programme offers a flexible and comprehensive learning experience tailored to the needs of accounting professionals.",
        "When you sign up as a mentee, you can expect personalised guidance from mentors who are experienced in your particular job role. Your chosen accounting clerk career coach will cover essential topics such as bookkeeping, financial reporting, and the use of accounting software.",
        "They’ll provide practical tips and share their best practices, teaching you how to handle complex accounting tasks and improve your accuracy. Additionally, they’ll offer regular feedback and conduct performance reviews to track your progress and address areas that you might need to improve in.",
        "One of the biggest benefits of our accounting clerk mentor programme online is that it allows you to learn at your own pace, making it easier to balance work and your other commitments. This course gives you the opportunity to learn from mentors who truly understand the problems you’re facing because they’ve been there and done that too.",
        "This programme will provide you with a solid support system and may also lead to great networking opportunities that could take your career to new heights.",
        "Overall, when you sign up for our accounting clerk mentorship course, you can expect to build valuable skills, improve your confidence, and gain access to a supportive network, contributing to your professional development and career advancement.",
      ]
    },
    {
      title: "Find an Accounting Clerk Mentor at Career Navig8r Today",
      description: [
        "Whether you’re choosing a new career or keen to excel in your existing job role, you may not know the best route to success. And if you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have a lot of questions about the industry you’re breaking into, such as what to expect, which skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now. ",
      ]
    },
  ]
};

export const staffAccountantData = {
  mainTitle: "Staff Accountant Career Mentor",
  accordionData: [
    {
      title: "About Staff Accountant Mentoring",
      description: [
        "Have you just started your career as a staff accountant and want to enhance your job skills? Or have you been in the job role for a while and are looking to rise through the ranks?",
        "Career Navig8r offers a staff accountant mentoring programme designed to guide and develop junior accounting professionals like you. Throughout this course, you’ll gain valuable insights, technical knowledge, and practical skills from experienced staff accountants who will act as your career mentors.",
        "Our staff accountant course focuses on enhancing your competency in financial reporting, tax preparation, auditing, and compliance. You’ll also develop your soft skills, such as communication, problem-solving, and time management. In this supportive learning environment, you’ll gain confidence and proficiency in your role, helping you to improve your job performance and putting you in a better position to advance in your career.",
        "Our effective staff accountant career mentoring can bridge the gap between your existing academic knowledge and real-world application, equipping you to become part of a well-rounded and competent accounting workforce.",
        "To find your ideal staff accountant career coach and grow in your career, sign up with Career Navig8r now.",
      ]
    },
    {
      title: "The Job Role of a Staff Accountant",
      description: [
        "To excel in your role as a staff accountant, you need to have a clear understanding of what your job role entails.",
        "In this role, you’ll be required to handle a wide range of financial tasks essential to the smooth operation of your organisation. Primarily, you’ll be responsible for maintaining accurate financial records, including preparing and analysing financial statements, managing ledgers, and ensuring compliance with accounting principles and regulations. You’ll handle routine accounting functions, such as accounts payable and receivable, payroll processing, and bank reconciliations.",
        "Additionally, you’ll typically be required to assist in the preparation of tax returns, budget reports, and audit documentation. You’ll play an important role in month-end and year-end closing processes, ensuring that all financial transactions are accurately recorded and reported. You’ll also analyse financial data to identify trends, discrepancies, and opportunities for cost savings and efficiency improvements.",
        "Beyond these technical tasks, you’ll often need to collaborate with other departments to provide financial insights and support decision-making processes. You’ll need meticulous attention to detail, strong analytical skills, and an ability to work under pressure if you want to competently maintain the financial health and integrity of your organisation.",
      ]
    },
    {
      title: "Challenges Faced by Staff Accountants in the Industry",
      description: [
        "As a staff accountant, you’ll encounter numerous challenges in your role, often stemming from the complex nature of the financial industry.",
        "One significant challenge is keeping up with the UK’s ever-changing accounting standards and regulations. You’ll need to constantly update your knowledge and adapt to new rules, which can be demanding and time-consuming.",
        "You may also struggle with your workload and time management. You’ll have to get used to juggling multiple responsibilities, from daily financial transactions to preparing comprehensive reports and assisting with audits. During peak periods, such as the fiscal year-end or tax season, your workload will intensify, so you’ll need to work longer hours with increased pressure to meet deadlines.",
        "The technological advancements will also come with opportunities and challenges. While new accounting software and tools can enhance your efficiency, they require you to continuously learn and adapt to their interfaces. You’ll need to stay proficient with evolving technologies and ensure data accuracy and security, especially with the rise of cyber threats.",
        "Another challenge is the need for meticulous attention to detail. Are any errors in your financial records will result in significant consequences, including financial loss and even reputational damage. That’s why this job demands a high level of precision and diligence.",
        "Additionally, you may find it difficult to communicate  effectively. You’ll have to translate complex financial data into clear, actionable insights for non-financial stakeholders, which requires strong interpersonal skills and the ability to simplify technical jargon. Balancing your technical expertise with soft skills is essential for success in this role.",
        "To effectively tackle these challenges, you’ll greatly benefit from the guidance of someone who understands the ins and out of your job role. A staff accountant career mentor can help you easily work through these complexities. Sign up to get started today.",
      ]
    },
    {
      title: "Why do Staff Accountants Need Mentoring?",
      description: [
        "Staff accountant mentoring can help you learn how to apply your academic knowledge in real situations.",
        "If you’re a fresh graduate, you’ll enter the workforce with theoretical understanding but a lack of real-world experience. Your staff accountant career coach will provide you with the practical insights and hands-on skills necessary to excel in your role.",
        "Another key reason for staff accountant coaching is to help you get to grips with  accounting regulations and standards. A staff accountant career mentor with experience can help you stay compliant and up-to-date with everything you need to know to do your job safely and properly. This guidance will help you build on your understanding of financial reporting, tax laws, and auditing requirements.",
        "Staff accountant career coaching can also help you develop your essential soft skills. Effective communication, problem-solving, and time management are important for success but are often not emphasised in formal education. Through mentorship, you can learn to convey financial information clearly to non-financial stakeholders, manage multiple tasks efficiently, and develop your critical thinking skills.",
        "From a mental health perspective, staff accounting mentoring will provide you with a much-needed support system. The transition from an academic environment to a professional setting can be daunting. Having a mentor can give you the emotional and professional support you need, boosting your confidence and reducing your anxiety. Your mentor will also give you feedback, encouraging you to keep learning, improving, and striving to be the best.",
        "Overall, our staff accountant career coaching programme will equip you with the necessary tools, knowledge, and confidence to advance in your career and contribute effectively to your organisation.",
      ]
    },
    {
      title: "What to Expect from a Staff Accountant Mentor Programme Online?",
      description: [
        "At Career Navig8r, our online staff accountant course offers a comprehensive and flexible approach to professional development.",
        "Once you sign up, you can expect personalised guidance from a staff accountant mentor who’s experienced in this specific job role. They’ll provide insights into accounting best practices, industry standards, and career advancement strategies.",
        "The programme will typically include virtual one-on-one sessions with your chosen staff accountant career coach, covering key topics such as financial reporting, tax preparation, and auditing techniques.",
        "As a mentee, you’ll benefit from assessments and real-time feedback on your work, helping you improve your accuracy and efficiency. You may also have access to networking opportunities with industry professionals, providing you with a supportive community for sharing experiences and advice.",
        "The convenience of our online platform allows for flexible scheduling, so you can balance your professional and personal commitments effectively. You can choose exactly when you’d like to have your mentoring sessions with your staff accountant coach.",
      ]
    },
    {
      title: "Find a Staff Accountant Mentor at Career Navig8r Today",
      description: [
        "Whether you’re choosing a new career or keen to excel in your existing job role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have a lot of questions about the industry you’re breaking into, such as what to expect, which skills you'll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now. ",
      ]
    },
  ]
};

export const itDirectorData = {
  mainTitle: "IT Director Career Mentor",
  accordionData: [
    {
      title: "About IT Director Mentoring",
      description: [
        "Have you recently taken on the role of an IT director and want to learn more about your responsibilities? Or have you been in this position for a while and want to become better at tackling challenges or climb the corporate ladder?",
        "Career Navig8r’s IT director mentoring programme is designed to help guide aspiring IT leaders like you through the complex challenges of technology management. This mentorship opportunity enables you to develop your strategic thinking, leadership skills, and technical expertise.",
        "You’ll be able to choose an experienced IT director mentor of your choice, and benefit from their insights and experience to help you manage teams and align your IT strategies with business goals. As a mentee, you’ll gain valuable perspectives on industry trends, risk management, and exciting developments in your field.",
        "Our IT director training programme provides a collaborative environment where you can grow through real-world experiences and personalised guidance. Whether you're an emerging leader or an experienced professional, our IT Director course will encourage you to advance your career with confidence.",
        "Ready to find your ideal IT director mentor? Sign up with Career Navig8r today.",
      ]
    },
    {
      title: "The Job Role of an IT Director",
      description: [
        "As an IT director, you play an important role in shaping and executing your organisation's technology strategy. You’re responsible for overseeing the entire IT department and ensuring that all systems, networks, and applications run smoothly and align with business objectives.",
        "Your duties include managing IT budgets, setting technology goals, and leading a team of IT professionals, including developers, network administrators, and support staff. You need to stay updated on the latest technology trends, keep your business safe from cybersecurity threats, and implement industry best practices to make informed decisions.",
        "Your job will involve collaborating closely with other departments to understand their needs and deliver solutions that are efficient and innovative. Strategic planning is another key part of your role, and it requires having a strong understanding of your business and its future needs.",
        "As an IT director, you’ll also play a role in vendor management, and you may be required to handle contract negotiations and ensure compliance with relevant regulations. Overall, your leadership is essential to your company's digital transformation and success.",
      ]
    },
    {
      title: "Challenges Faced by IT Directors in the Industry",
      description: [
        "As an IT director, there’s a good chance that you’ve worked your way up to your role and have years of experience in this industry. But there’s still a good chance that in this specific job role, you may still encounter some challenges that you haven’t faced before.",
        "One of the biggest challenges is keeping up with the pace of technological change. There are new tools, platforms, and innovations constantly emerging, which means you need to continuously update your knowledge and ensure that your organisation's technology infrastructure remains competitive and secure.",
        "Cybersecurity is another major concern. With cyberattacks becoming increasingly sophisticated, you’re probably under constant pressure to protect your company’s sensitive data and maintain the very best security measures. This involves staying ahead of potential threats, implementing strict security protocols, and ensuring you’re compliant with industry regulations.",
        "Budget constraints can also be challenging. As an IT director, you’ll often need to balance the demands for cutting-edge technology with limited financial resources. You’ll need to prioritise certain investments over others, justify expenses to your peers and stakeholders, and find cost-effective solutions that meet your organisational needs without compromising on quality.",
        "Additionally, managing and retaining skilled IT talent is a constant challenge. The demand for experienced professionals is often higher than the supply, which means you’ll need to compete for the most talented staff. A big part of this is creating a positive work environment that attracts employees, and providing ongoing training and career growth opportunities to retain skilled staff.",
        "It’s important that your IT strategies are aligned with the overall business goals, so you should be able to effectively communicate the value of technology investments to non-technical stakeholders, which has the potential to be frustrating.",
        "This is where having an IT director mentor will come in handy. Your mentor was once exactly where you are now, and they’ve dealt with similar challenges. Their expert guidance can help you easily tackle these obstacles.",
        "If you’re ready to get started, sign up for our IT director training course today.",
      ]
    },
    {
      title: "Why do IT Directors Need Mentoring?",
      description: [
        "Regardless of how experienced you are in this job role, you could still benefit from our IT director mentoring programme. ",
        "Your role demands a blend of technical expertise, strategic thinking, and people management skills, and our IT director course will provide you with invaluable guidance for mastering your diverse responsibilities. Your chosen IT director mentor with experience in the field can offer insights into the best practices and help you avoid common pitfalls in your role. They’ll be able to share real-world experiences that textbooks or formal education can’t provide.",
        "One of the key benefits of our IT director training course is that you’ll have the opportunity to refine your leadership skills. Managing diverse teams and making critical decisions under pressure are areas where experienced IT director mentors can provide tailored advice. This essential guidance will help you balance technical demands with business objectives, often under tight deadlines and with limited resources.",
        "IT director mentoring will also help you stay ahead of industry trends and innovations. By learning from someone with a broader or more seasoned perspective, you can anticipate changes and adapt your strategies proactively, rather than reactively.",
        "Additionally, our IT director training course will support your personal development by enhancing your communication, negotiation, and conflict resolution skills, all of which are important for handling the organisational dynamics of this IT leadership role.",
      ]
    },
    {
      title: "What to Expect from IT Director Training Online?",
      description: [
        "At Career Navig8r, we’ve designed our online IT director mentoring programme to give you a flexible and accessible way to gain valuable insights and guidance from seasoned professionals in the field.",
        "When you sign up on our platform, you can choose an IT director mentor who’ll provide you with personalised mentorship tailored to your specific career goals and challenges. Our IT director course typically includes one-on-one sessions and virtual meetings, allowing you to discuss real-world scenarios and develop actionable strategies with your mentor. You’ll also have the opportunity to receive constructive feedback to help you do your job better.",
        "The online format of this IT director training course means you can pick the most convenient time for your sessions, so they don’t disrupt your daily schedule. You’ll be able to connect with mentors from diverse backgrounds and industries, expanding your network and exposure to different perspectives.",
        "In our IT director mentoring programme, you can expect a focus on leadership development, strategic thinking, and technical acumen, with practical advice on sustaining a successful career in IT management. You’ll also receive support in areas like team management, risk assessment, and aligning IT initiatives with business objectives.",
        "Overall, our IT director training online is designed to empower professionals like you with the knowledge and skills needed to confidently advance in their careers.",
      ]
    },
    {
      title: "Find an IT Director Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },

  ]
};

export const seniorAccountantData = {
  mainTitle: "Senior Accountant Career Mentor",
  accordionData: [
    {
      title: "About Senior Accountant Mentoring",
      description: [
        "Are you a newly promoted senior accountant looking to learn the ins and outs of the job role? Or have you been in this role for a while and want to climb the corporate ladder?",
        "Career Navig8r offers a senior accountant career mentoring programme to guide and support accountants in their professional growth. In this course, you can choose a mentor from our pool of experienced senior accountants and benefit from their insights, industry knowledge, and practical advice to help you grow your career. ",
        "Your mentor will help you handle complex financial tasks more effectively, confidently deal with compliance issues, and face leadership challenges head-on. This senior accountant course will also encourage you to work harder and advance in your specific job role. ",
        "In your regular online sessions, your senior accountant career mentor will offer hands-on guidance and constructive feedback to help you sharpen your technical abilities and understand industry trends. They’ll also assist you in developing the strategic thinking necessary to excel in your role and contribute meaningfully to your organisation.",
        "Want to rise up the ranks in your job role? Sign up with Career Navig8r today and find your ideal senior accountant career coach.",
      ]
    },
    {
      title: "The Job Role of a Senior Accountant",
      description: [
        "To succeed in your career as a senior accountant, you need to have a thorough understanding of what your job role entails.",
        "As a senior accountant, you’ll have a broad range of financial responsibilities, making you a key asset to your organisation. You’ll be responsible for preparing accurate financial statements, ensuring compliance with accounting standards and regulations, and managing the general ledger. You’ll also oversee month-end and year-end closing processes, reconcile accounts, and analyse financial data to provide insights for decision-making.",
        "In addition to these tasks, you’ll often need to supervise the junior accounting staff, offering guidance and mentoring to enhance their skills and efficiency. You’ll also collaborate with auditors during internal and external audits, ensuring all financial records are accurate and up to date.",
        "As a senior accountant, you’ll play an important role in budgeting, forecasting, and financial planning, providing management with reports and recommendations to optimise financial performance. Your expertise in accounting principles and attention to detail will help ensure your organisation’s financial health and compliance with regulatory requirements.",
      ]
    },
    {
      title: "Challenges Faced by Senior Accountants in the Industry",
      description: [
        "As a senior accountant, you’re likely to face numerous challenges within the complex financial industry.",
        "One of the primary challenges you’ll face is keeping up with constantly evolving accounting standards, regulations, and tax laws. As these rules change, you’ll need to ensure that your organisation's financial practices remain compliant, which means you need to be willing to continuously learn and adapt.",
        "Another significant challenge is managing the increasing volume of financial data. With the rise of digital technologies and big data, you’ll need to efficiently handle and analyse large datasets to extract meaningful insights. This requires you to be proficient in advanced accounting software and analytical tools, as well as being capable of maintaining accuracy under pressure.",
        "You’ll need to be able to handle tight deadlines in this job role, particularly during month-end and year-end close processes. Balancing multiple priorities, such as preparing financial statements, reconciling accounts, and managing audits, can be stressful, regardless of your experience.",
        "You may also face challenges in managing and mentoring your junior staff. It’s your job to ensure that your team maintains a high level of performance while also developing their skills.",
        "Communicating complex financial information clearly to non-financial stakeholders adds another layer of difficulty. This requires you to have both strong technical knowledge and excellent communication skills.",
        "Overall, these issues demand a high level of expertise, resilience, and adaptability from you as a senior accountant. That’s where the expertise of a senior accountant career coach can come in handy, as you’ll be able to learn from somebody who has extensive experience in dealing with similar challenges in their career.",
      ]
    },
    {
      title: "Why do Senior Accountants Need Mentoring?",
      description: [
        "Whether you’re new to the role or you have extensive experience and knowledge as a senior accountant, you can still greatly benefit from senior accountant coaching. The finance industry requires continuous learning, and a senior accountant course will offer you a structured solution to gain fresh perspectives and insights.",
        "Even as a seasoned professional, you may still face challenges like adapting to new regulations, technological advancements, and evolving industry standards. A senior accountant mentor with specialised expertise can guide you through these changes, offering advice on the best practices and approaches.",
        "Our senior accountant career coaching will also help you refine your leadership and management skills. If you’re keen to improve how you supervise your junior staff, a senior accountant career mentor can provide valuable strategies for effective team management, communication, and conflict resolution. This guidance is important for creating a positive work environment and ensuring the team’s productivity and growth.",
        "Additionally, signing up for a senior accountant career coaching session with us will give you a safe space to discuss your career development. Your mentor can help you to explore new career paths and expand your professional network, or simply stay motivated in your existing job role. They’ll also provide emotional support, helping you handle workplace challenges and maintain a healthy work-life balance.",
        "Overall, senior accountant mentoring can be an essential tool for enhancing your skills, keeping up with industry trends, and continuing to grow both professionally and personally. Sign up for our senior accountant career mentoring course today.",
      ]
    },
    {
      title: "What to Expect from a Senior Accountant Mentoring Course Online?",
      description: [
        "At Career Navig8r, our online senior accountant coaching programme will give you a structured and flexible platform to grow professionally and expand your skill set. By signing up as a mentee, you’ll receive personalised guidance from one of our experienced senior accountant coaches, who will provide insights into everything you need to know to excel in your career.",
        "Our senior accountant course typically includes regular virtual meetings, where you and your mentor can discuss challenges you’re facing in your role, your career goals, and strategies for success. The online format of these one-on-one senior accountant mentoring sessions means you can pick a convenient time that doesn’t disrupt your daily schedule.",
        "As a mentee, you can expect constructive feedback from your mentor, helping you to further refine your technical skills and decision-making abilities. Additionally, our senior accountant coaching programme provides great networking opportunities, connecting you with peers and industry professionals globally.",
        "Our online senior accountant course offers a comprehensive and convenient way for accountants like you to develop their expertise, advance their careers, and stay competitive in a rapidly evolving industry.",
      ]
    },
    {
      title: "Find a Senior Accountant Mentor at Career Navig8r Today",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const accountsManagerData = {
  mainTitle: "Accounts Manager Career Mentor",
  accordionData: [
    {
      title: "About Accounts Manager Mentoring",
      description: [
        "Have you recently become an accounts manager and want to gain a better understanding of your job role and responsibilities? Or do you want to learn how to overcome challenges in this role and steadily rise up the ranks?",
        "Career Navig8r’s accounting manager mentoring programme is designed to help you enhance your job-specific skills and knowledge, guiding you toward becoming a proficient accounts manager.",
        "When you sign up for our mentoring programme, you’ll receive personalised guidance from your chosen accounts manager career coach. Your mentor will draw on their own expertise and extensive experience in the field to teach you all about financial management, client relationships, and strategic decision-making.",
        "Our accounts manager course uses real-world applications to help you build on your leadership development and problem-solving skills, ensuring that you’re well-prepared for higher responsibilities. By giving you a deeper understanding of industry practices and team management, our course will equip you with the tools needed to excel in your job role and advance your career in accounting and finance.",
        "Ready to find your accounts manager career mentor? Sign up with Career Navig8r today.",
      ]
    },
    {
      title: "The Job Role of an Accounts Manager",
      description: [
        "As an accounts manager, your main job is to maintain and grow your company’s financial health. You’re responsible for overseeing and managing client accounts, ensuring that all financial transactions are accurately recorded and all accounts are reconciled on time.",
        "In this role, you’ll need to prepare financial reports, budgets, and forecasts, as well as analyse financial data to provide insights and recommendations for decision-making. You’ll also have to manage relationships with clients, suppliers, and other stakeholders, ensuring that payment terms and contracts are adhered to and that any issues are quickly resolved.",
        "Aside from financial management, your job also includes leading a team of accountants and providing guidance and support to ensure that the department operates efficiently. You need to stay updated on all accounting regulations and industry trends to ensure compliance and implement best practices.",
        "To succeed in your career as an accounts manager, you’ll need effective communication skills and strong analytical skills. You’ll also need good attention to detail to ensure that no essential aspects are overlooked.",
        "Overall, this is a dynamic and demanding job role that you can learn to handle better with the advice and guidance of an experienced accounts manager coach.",
      ]
    },
    {
      title: "Challenges Faced by Accounts Managers in the Industry",
      description: [
        "Even with years of experience in the accounting industry, you’re still likely to encounter some unique challenges that will test your expertise and problem-solving abilities.",
        "One of these challenges is managing complex financial regulations and compliance. As accounting standards and tax laws frequently change, you need to stay updated to ensure that your company's practices remain compliant. This requires continuous learning and vigilance.",
        "Another significant challenge is handling large volumes of data. As an accounts manager, you’ll often deal with vast amounts of financial information, requiring you to implement and maintain accurate, efficient systems for data management and analysis. This is essential not only for internal reporting but also for providing insights that help with strategic business decisions.",
        "Balancing multiple client relationships can also be difficult. It’s your job to ensure that all clients are satisfied, which will likely involve managing expectations, deadlines, and deliverables. For this, you’ll need to have excellent communication and time-management skills, especially when you’re dealing with conflicting priorities.",
        "Additionally, as the finance industry is increasingly relying on technology, you may face some challenges related to cybersecurity. To ensure that your company’s financial data is secure, you need to stay informed about the latest cybersecurity threats and solutions.",
        "Lastly, it can be extremely stressful to manage and motivate a team while ensuring you meet tight deadlines and financial targets. This is where you’ll need strong leadership and interpersonal skills to juggle your responsibilities effectively.",
        "The expert guidance of an accounting manager career coach is just what you need to tackle these challenges. Your mentor was once exactly where you are now in your career, and they’ve most likely dealt with similar problems.",
        "Sign up for our accounts manager course and find your accounting manager mentor today.",
      ]
    },
    {
      title: "Why do Accounts Managers Need Mentoring?",
      description: [
        "No matter how experienced you are in your industry, you could still benefit from our accounts manager coaching programme. It’ll help you become better at doing your job and advance in your career.",
        "Your chosen accounts manager career mentor has extensive experience in your job role, which means they can provide you with all the personalised guidance and support you need. This is particularly important in the rapidly changing finance industry where regulations, technologies, and best practices evolve quickly.",
        "With the help of an accounts manager career coach, you’ll be able to develop or build on your critical skills, such as leadership, communication, and problem-solving, which are essential for managing teams and making informed decisions. By learning from your accounts manager mentor's experiences, you can avoid common pitfalls and improve your understanding of the financial landscape. This will enable you to handle challenges with confidence.",
        "Our accounts manager course is designed to help you grow professionally. You’ll have the opportunity to refine your technical skills and adapt to new technologies, such as advanced accounting software and data analytics tools. This ongoing learning process is essential for staying competitive in the field.",
        "Additionally, our accounting manager mentoring will provide emotional support, helping you cope with the stress and pressure that comes with your responsibilities. Your mentor will encourage you to set and achieve personal and professional goals, ultimately helping you to feel more job satisfaction, advance in your career, or simply make a more significant impact on your organisation.",
      ]
    },
    {
      title: "What to Expect from Our Accounts Manager Mentoring Course Online?",
      description: [
        "At Career Navig8r, our accounts manager course online offers a comprehensive, flexible, and personalised learning experience tailored to the unique needs of aspiring and current accounts managers like you.",
        "When you sign up as a mentee, you’ll be able to schedule mentorship sessions with your accounts manager career coach, covering key areas such as financial reporting, budgeting, client relationship management, and compliance with industry regulations.",
        "In this interactive course, your accounts manager mentor will teach you how to apply your knowledge in real-world situations and provide constructive feedback. They may also give you practical assignments to test your progress.",
        "Throughout these one-on-one mentorship sessions, you’ll gain valuable insights into advanced financial management techniques, empowering you to excel in your role and further develop your leadership skills. The online format also allows you to learn at your own pace, making it easy to balance your daily schedule and responsibilities.",
        "By the end of the course, you’ll be equipped with the knowledge, tools, and confidence to more effectively manage complex accounts, lead a team, and contribute strategically to your organisation’s financial success. Our accounts manager career coaching will provide you with excellent networking opportunities, should you be looking to connect with helpful contacts and expand your social circle at work.",
      ]
    },
    {
      title: "Find an Accounts Manager Career Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};

export const chiefFinancialOfficerData = {
  mainTitle: "Chief Financial Officer Career Mentor",
  accordionData: [
    {
      title: "About Chief Financial Officer Mentoring",
      description: [
        "Are you a recently promoted CFO looking for career advice from someone who has extensive experience in this job role? Or have you been working as a CFO for a while and want to improve your job skills and seek a raise or promotion?",
        "Career Navig8r’s specialised chief financial officer mentoring programme is designed to guide aspiring, newly appointed, or current CFOs through the complexities of financial leadership. ",
        "This chief financial officer course will help you enhance your strategic decision-making, financial acumen, and leadership skills, all of which are essential for navigating the high-stakes world of corporate finance.",
        "In this programme, you can select an experienced CFO of your choice to be your chief financial officer career mentor. They’ll provide you with invaluable insights, sharing their own real-world experiences and industry best practices to help you build your confidence and competency in your role. ",
        "You’ll receive personalised guidance from your chief financial officer career coach, who’ll help you build strong financial strategies, learn to better manage risks, and drive organisational growth.",
        "The main outcome of our chief financial officer course is to shape the next generation of financial leaders to excel in their careers. If you’re keen to climb the corporate ladder in your organisation, sign up with us and pair with your chief financial officer mentor today.",
      ]
    },
    {
      title: "The Job Role of a Chief Financial Officer",
      description: [
        "To excel as the CFO in your company, you need to understand exactly what your job role entails and what your responsibilities are.",
        "In your executive role as a chief financial officer, you’re mainly responsible for managing and overseeing all financial aspects within your organisation. Your primary duties include financial planning, risk management, record-keeping, and financial reporting. You’ll work closely with the CEO and other senior executives to develop and implement financial strategies that align with your company’s goals.",
        "You’ll also be tasked with maintaining your organisation’s financial health by monitoring cash flow, managing investments, and optimising the capital structure. You’ll play an essential role in strategic decision-making, providing insights and forecasts that can guide long-term planning and corporate strategy.",
        "Additionally, as the CFO, you’ll need to ensure compliance with financial regulations and standards, while managing relationships with stakeholders, including investors, auditors, and regulators.",
        "If you work in a large organisation, your duties will also include overseeing departments such as accounting, finance, and treasury, which makes your role central to your company’s success and sustainability.",
      ]
    },
    {
      title: "Challenges Faced by Chief Financial Officers in the Industry",
      description: [
        "As a chief financial officer, you probably have years of experience in finance and are well-versed in the ups and downs of this industry. However, in this role, you’re still likely to face challenges that will make your job increasingly complex and demanding.",
        "One of the primary challenges you’ll encounter is managing financial risk amidst economic volatility. Due to the constant fluctuations in global markets, changes in interest rates, and currency exchange risks, you need to be vigilant and proactive in preventing potential financial losses.",
        "Another common challenge is regulatory compliance. With financial regulations continuously evolving, you need to ensure that your organisation follows local and international standards. Non-compliance can result in severe penalties and damage to your company’s reputation.",
        "As a CFO, you’ll also often grapple with technological advancements. To keep up with the rise of digital finance, automation, and big data analytics, you need to stay updated with the latest technologies and integrate them into all financial operations. You’ll also need to ensure that investing in new technologies doesn’t disrupt the cost efficiency of your company.",
        "Talent management is another major issue. As finance teams evolve, it’s important that you attract, retain, and train skilled professionals who are capable of navigating complex financial landscapes.",
        "Finally, in many cases, you’ll be expected to provide strategic leadership beyond financial management. You’ll be required to collaborate with other executives to drive business growth, which means you’ll need a deep understanding of the industry, market trends, and competitive dynamics.",
        "Balancing all of these responsibilities can make your role particularly challenging, especially without the guidance and support of someone who has been there, done that. This is where you could benefit from the expertise of a chief financial officer coach.",
        "These professionals were once in the same position as you, which means they’ve faced and successfully dealt with similar challenges in their careers. That’s what makes them the ideal chief financial officer career mentors for you.",
        "Sign up for our chief financial officer course today.",
      ]
    },
    {
      title: "Why do Chief Financial Officers Need Mentoring?",
      description: [
        "As a CFO, you’re constantly operating in a high-stakes environment where your decisions can significantly impact the financial health and strategic direction of your organisation. Given the complexities of this role, you could greatly benefit from the guidance of a chief financial officer career mentor, particularly if you’re newly appointed or transitioning into more challenging environments.",
        "Our chief financial officer coaching programme will provide you with invaluable support and advice from seasoned professionals who have worked through similar challenges. This guidance will help you develop a deeper understanding of financial strategy, risk management, and leadership, all of which are essential to your success.",
        "Your chosen chief financial officer career mentor can also help you to work on your ability to make informed decisions under pressure and manage stakeholder relationships. They can use their own on-the-job experience to teach you how to effectively communicate complex financial information to non-financial executives and board members.",
        "Additionally, participating in our chief financial officer course will help you stay updated with industry trends and regulatory changes, as well as the latest technological advancements. It’ll also offer a platform for discussing any confidential challenges you may be facing and exploring innovative solutions in a safe and supportive environment.",
        "If you’re a CFO aiming to transition from a purely financial focus to a more strategic leadership role, our chief financial officer career coaching can be instrumental. It’ll help you broaden your perspective, align your financial strategies with your overall business goals, and contribute more effectively to your organisation’s growth.",
      ]
    },
    {
      title: "What to Expect from Our Chief Financial Officer Mentoring Course Online?",
      description: [
        "Career Navig8r’s chief financial officer course online is designed to provide all the guidance and support you need as a CFO.",
        "Once you sign up, you’ll receive personalised chief financial officer career coaching from experienced financial leaders who bring their own real-world insights into the complexities of the CFO role. Throughout the course, your chosen chief financial officer mentor will guide you on topics including strategic financial planning, risk management, regulatory compliance, and leadership skills.",
        "You’ll participate in interactive sessions and receive one-on-one mentoring, equipping you with practical knowledge to handle the challenges you’re likely to face in your role.",
        "Our chief financial officer coaching course will help you develop a strategic mindset, enabling you to align your financial strategies with broader business goals effectively. You’ll also be able to network with numerous industry professionals, enhancing your future career prospects.",
        "The online format of our chief financial officer mentoring programme means that you can choose the most convenient time for your sessions, so you can balance your daily schedule and professional responsibilities.",
        "Whether you're looking to refine your financial awareness, enhance your leadership abilities, or transition into a higher role, this online chief financial officer course offers the tools and mentorship you need to excel.",
        "Find the ideal chief financial officer career coach and get a tailored learning experience that empowers you to lead with confidence.",
      ]
    },
    {
      title: "Find a Chief Financial Officer Career Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best route to success. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have numerous questions about what to expect, what skills you’ll need, and what employers are looking for when they’re hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now. ",
      ]
    },

  ]
};

export const itSupportSpecialistData = {
  mainTitle: "IT Support Specialist Career Mentor",
  accordionData: [
    {
      title: "About IT Support Specialist Mentoring",
      description: [
        "Are you an emerging IT professional looking to become an IT support specialist? Or are you already working in this job role and aiming to enhance your skills and keep growing in the IT industry?",
        "Either way, you’ll greatly benefit from pairing with a career mentor who’s experienced in this specific job role.",
        "Career Navig8r’s IT support specialist mentoring programme can help bridge the gap between your theoretical knowledge and practical experience. Through one-on-one guidance with an experienced professional in this role, you’ll gain real insights from someone who’s been there, done that, deepening your knowledge of problem-solving, customer interaction, and technical troubleshooting.",
        "Your chosen mentors will provide personalised advice, share real-world scenarios, and offer feedback on your performance, supporting you in your professional growth. This job-specific IT mentorship will enhance your skills and confidence while also helping you learn the best practices and gain up-to-date industry knowledge.",
        "With proper IT support specialist training, you’ll become well-prepared, competent, and capable of delivering high-quality IT support services in a rapidly evolving tech landscape.",
      ]
    },
    {
      title: "The Job Role of an IT Support Specialist",
      description: [
        "Whether you’re already an IT support specialist or aspiring to become one, you need to have a clear understanding of what this job role entails.",
        "An IT support specialist helps to ensure the smooth operation of an organisation's IT infrastructure. In this role, you’ll be responsible for diagnosing, troubleshooting, and resolving hardware, software, and network-related issues.",
        "You’ll be tasked with providing technical support to end-users, both remotely and in person, ensuring minimal disruption to your company’s operations. Your key responsibilities will typically include installing and configuring computer systems, updating software applications, and maintaining IT security protocols.",
        "As an IT support specialist, you’ll also be solely or jointly responsible for setting up new devices, managing user accounts, and ensuring that the data backup and recovery processes are in place. You’ll work closely with other IT professionals to implement system updates and upgrades, and you’ll document technical issues and solutions for future reference.",
        "Strong communication skills are essential here, as you have to translate complex technical information into understandable terms for non-technical users. Overall, as an IT support specialist, your job is to ensure the reliability and efficiency of your organisation's IT systems, contributing to overall business productivity and success.",
        "If you’re new to this role and need expert guidance, sign up for our IT support specialist course today.",
      ]
    },
    {
      title: "Challenges Faced by IT Support Specialists in the Industry",
      description: [
        "New and experienced IT Support Specialists face numerous challenges in the industry due to the multi-faceted nature of technology and the evolving demands of organisations.",
        "If you’re a new IT support specialist, the steep learning curve can be overwhelming. You’ll need to quickly acquire a deep understanding of various hardware and software systems, often while juggling multiple other IT support tasks.",
        "Handling a diverse range of issues, from simple password resets to complex network problems, requires you to have both broad and specific knowledge. You’ll also need to develop strong communication skills to assist users who may have limited technical understanding.",
        "If you’re an experienced IT support specialist, you’ll encounter your own unique set of challenges. You’ll be expected to keep up with the rapid pace of technological advancements and continuously update your skills. This includes staying informed about the latest security threats and best practices for mitigating risks. The pressure to resolve issues promptly can also lead to high stress levels, particularly in environments where downtime directly impacts business operations.",
        "Whether you’re a new or experienced specialist, you’ll need to manage customer expectations, often dealing with frustrated or impatient users. Balancing this customer service aspect with technical problem-solving is key to providing top service in your role. You’ll also be expected to work collaboratively within your team and with other departments, ensuring that the IT solutions you’re providing align with broader organisational goals.",
        "If you need help developing these skills, you could benefit from the guidance of someone who has been in your shoes and dealt with the same issues. An IT support specialist coach can help you become adaptable and resilient, equipping you with the expertise and mindset to thrive in this demanding yet rewarding field.",
      ]
    },
    {
      title: "Why do IT Support Specialists Need Mentoring?",
      description: [
        "The IT industry is fast-paced and ever-evolving, and you’ll need to be prepared to invest in continuous learning and adaptation if you want to stay at the top of your game. That’s where job-specific career mentoring can be beneficial. Using Career Navig8r’s platform, you can choose an IT support specialist mentor who’ll help you develop your skills and excel in your job role.",
        "If you’re a graduate looking to take on this job role, mentoring will give you a structured learning path, teaching you how to apply your academic knowledge in real-world situations. Your chosen experienced IT support specialist coach will offer valuable insights, practical tips, and troubleshooting techniques that may not have been covered in your formal education, based on their own firsthand experience.",
        "IT support specialist training can also help build confidence in your problem-solving abilities. By working closely with seasoned professionals, you’ll learn to approach complex issues methodically and efficiently. You’ll learn faster with hands-on guidance, enabling you to handle a broader range of technical problems independently.",
        "If you’re already working in this role, mentoring will help you continue your professional development and stay updated on the latest industry trends, tools, and best practices. Your IT support specialist coach will share their experiences and lessons learned, helping you avoid common pitfalls and make better-informed decisions.",
        "Our IT support specialist online training programme will also help you to enhance your soft skills, including communication, customer service, and teamwork. Your mentor will provide feedback on how to interact effectively with users, manage their expectations, and handle challenging situations.",
        "Overall, our IT support specialist mentoring ensures that you’re well-equipped to grow in the industry and adapt to its changes.",
      ]
    },
    {
      title: "What to Expect from IT Support Specialist Online Training?",
      description: [

        "Career Navig8r’s IT support specialist online training offers a comprehensive and flexible learning experience designed to equip you with essential skills and knowledge.",
        "After you sign up, you’ll choose your IT support specialist mentor. Your mentoring will cover a breadth of key topics, including hardware and software troubleshooting, network configuration, cybersecurity fundamentals, and customer service techniques. ",
        "Through this IT support specialist course, you can also expect to gain proficiency in common tools and technologies, such as operating systems, cloud services, and diagnostic utilities. Your IT support specialist coach may conduct assessments and quizzes to reinforce your understanding and track your progress.",
        "During each one-on-one online session, your chosen IT support specialist mentor will offer guidance and answer questions, ensuring that you have a well-rounded educational experience. They’ll help boost your problem-solving skills and prepare you to handle a variety of IT support scenarios, no matter where you are in your career.",
        "Need effective IT support specialist training to enhance your career? Sign up for our IT support specialist course now.",
      ]
    },
    {
      title: "Find an IT Support Specialist Mentor at Career Navig8r Today!",
      description: [
        "Whether you’re getting started in your career or striving to reach new heights in your existing role, you may not know the best path forward. If you’ve just begun your career in a new industry, you’ll especially find that trying to succeed without professional guidance can feel overwhelming.",
        "You’ll probably have a lot of questions about what to expect, what skills are needed, and what employers are looking for when hiring. That’s where you could benefit from working with a qualified and experienced career mentor, who can answer these questions and help you grow in your chosen career.",
        "Need a career mentor for your specific job role or industry? Sign up with Career Navig8r and find your ideal mentor now.",
      ]
    },
  ]
};
