import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import plusSignImage from './images/plus-sign.png';
import minusSignImage from './images/minus-sign.png';
import { 
  itData, 
  hrData, 
  financeData,

  itManagerData,
  marketingManagerData,
  complianceManagerData,
  dataScientistData,
  facilitiesManagerData,
  customerServiceRepresentativeData,
  accountingClerkData,
  staffAccountantData,
  itDirectorData,
  seniorAccountantData,
  accountsManagerData,
  chiefFinancialOfficerData,
  itSupportSpecialistData,
} from './accordionData.js'

import css from './Accordion.module.css';

const AccordionIT = props => {
  const { rootClassName, className, urlQueryParams, location } = props;

  const isIT = urlQueryParams?.pub_category === 'information_technology';
  const isHR = urlQueryParams?.pub_category === 'recruitment_and_hr';
  const isFinance = urlQueryParams?.pub_category === 'accountancy_banking_and_finance';

  const isItManager = !!location && location?.pathname?.indexOf('it-manager-informationtechnology') !== -1;
  const isMarketingManager = !!location && location?.pathname?.indexOf('marketing-manager-marketingandcommunications') !== -1;
  const isComplianceManager = !!location && location?.pathname?.indexOf('compliance-manager-consultingandstrategy') !== -1;
  const isDataScientist = !!location && location?.pathname?.indexOf('data-scientist-informationtechnology') !== -1;
  const isFacilitiesManager = !!location && location?.pathname?.indexOf('facilities-manager-administrationandofficesupport') !== -1;
  const isCustomerServiceRepresentative = !!location && location?.pathname?.indexOf('customer-service-representative-callcenterandcustomerservice') !== -1;
  const isAccountingClerk = !!location && location?.pathname?.indexOf('accounting-clerk-accounting') !== -1;
  const isStaffAccountant = !!location && location?.pathname?.indexOf('staff-accountant-accounting') !== -1;
  const isItDirector = !!location && location?.pathname?.indexOf('it-director-informationtechnology') !== -1;
  const isSeniorAccountant = !!location && location?.pathname?.indexOf('senior-accountant-accounting') !== -1;
  const isAccountsManager = !!location && location?.pathname?.indexOf('accounts-manager-accounting') !== -1;
  const isChiefFinancialOfficer = !!location && location?.pathname?.indexOf('chief-financial-officer-bankingandfinancialservices') !== -1;
  const isItSupportSpecialist = !!location && location?.pathname?.indexOf('it-support-specialist-informationtechnology') !== -1;

  if(
    !isIT && 
    !isHR && 
    !isFinance &&
    !isItManager &&
    !isMarketingManager &&
    !isComplianceManager &&
    !isDataScientist &&
    !isFacilitiesManager &&
    !isCustomerServiceRepresentative &&
    !isAccountingClerk &&
    !isStaffAccountant &&
    !isItDirector &&
    !isSeniorAccountant &&
    !isAccountsManager &&
    !isChiefFinancialOfficer &&
    !isItSupportSpecialist
  ) return null

  const [accordionIndex, setAccordionIndex] = useState(0);

  const onAccordionClick = (index) => {
    if (index === accordionIndex) setAccordionIndex(-1)
    else setAccordionIndex(index)
  }

  let data

  if(isIT) data = itData
  else if (isHR) data = hrData
  else if (isFinance) data = financeData
  else if (isItManager) data = itManagerData
  else if (isMarketingManager) data = marketingManagerData
  else if (isComplianceManager) data = complianceManagerData
  else if (isDataScientist) data = dataScientistData
  else if (isFacilitiesManager) data = facilitiesManagerData
  else if (isCustomerServiceRepresentative) data = customerServiceRepresentativeData
  else if (isAccountingClerk) data = accountingClerkData
  else if (isStaffAccountant) data = staffAccountantData
  else if (isItDirector) data = itDirectorData
  else if (isSeniorAccountant) data = seniorAccountantData
  else if (isAccountsManager) data = accountsManagerData
  else if (isChiefFinancialOfficer) data = chiefFinancialOfficerData
  else if (isItSupportSpecialist) data = itSupportSpecialistData

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>

      <h2 className={css.title}>
        {data?.mainTitle}
      </h2>

      <div className={css.accordion}>
        {data?.accordionData?.map((data, i) => {
          return (
            <div className={classNames(css.accordionItem, { [css.accordionItemActive]: accordionIndex === i })}>
              <h3 className={css.accordionTitle} onClick={() => onAccordionClick(i)}>
                {data.title}
                <img src={accordionIndex === i ? minusSignImage : plusSignImage} />
              </h3>
              {data.description.map(p => {
                return <p className={css.accordionDescription}>{p}</p>
              })}
            </div>
          )
        })}
      </div>
    </div>
  );
};

AccordionIT.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

AccordionIT.propTypes = {
  rootClassName: string,
  className: string,
};

export default AccordionIT;
