import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink } from '../../components';

import css from './SectionGdprStatementTop.module.css';

import leftImage from './images/left-image.png';

const SectionCommunityThrivelinesTop = props => {
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.rootWrapper}>
        <div className={css.steps}>
          <div className={css.stepPictureWrapper}>
            <div className={css.stepPicture}>
              <img
                className={css.explanationPicture}
                src={leftImage}
              />
            </div>
          </div>

          <div className={css.stepExplanationWrapper}>
            <h1 className={css.stepTitle}>
              <FormattedMessage
                id="SectionGdprStatementTop.part1Title"
                values={{ newLine: <br/>}}
              />
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionCommunityThrivelinesTop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionCommunityThrivelinesTop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionCommunityThrivelinesTop;
