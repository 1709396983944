import React, { Component } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { isAnyFilterActive } from '../../util/search';
import { propTypes } from '../../util/types';
import {
  SearchResultsPanel,
  SearchFiltersMobile,
  SearchFiltersPrimary,
  SearchFiltersSecondary,
  SortBy,
  SearchRequestClause,
  Button,
  IconClose,
} from '../../components';
import { JobRoleFilterSearchForm } from '../../forms';

import FilterComponent from './FilterComponent';
import { validFilterParams } from './SearchPage.helpers';

import css from './SearchPage.module.css';

import viewSelectedListImage from './images/card-view-selected-list.png';
import viewUnselectedListImage from './images/card-view-unselected-list.png';
import viewSelectedCardImage from './images/card-view-selected-card.png';
import viewUnselectedCardImage from './images/card-view-unselected-card.png';

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

export const VIEW_TYPE_CARD = 'card';
export const VIEW_TYPE_BAND = 'band';

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

/**
 * MainPanel contains search results and filters.
 * There are 3 presentational container-components that show filters:
 * SearchfiltersMobile, SearchFiltersPrimary, and SearchFiltersSecondary.
 * The last 2 are for desktop layout.
 */
class MainPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecondaryFiltersOpen: false,
      currentQueryParams: props.urlQueryParams,
      viewType: VIEW_TYPE_CARD
    };

    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);

    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);

    this.handleSubmit = this.handleSubmit.bind( this );

    this.setViewType = this.setViewType.bind( this );
  }

  setViewType( newViewType ){
    this.setState({ viewType: newViewType });
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, urlQueryParams, sortConfig, filterConfig } = this.props;
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { urlQueryParams, history, filterConfig } = this.props;
    const filterQueryParamNames = filterConfig.map(f => f.queryParamNames);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  initialValues(queryParamNames) {
    // Query parameters that are visible in the URL
    const urlQueryParams = this.props.urlQueryParams;
    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState ? currentQueryParam : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
          return { ...acc, [paramName]: getInitialValue(paramName) };
        }, {})
      : {};
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { urlQueryParams, history, sortConfig, filterConfig } = this.props;

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        return {
          currentQueryParams: { ...mergedQueryParams, ...updatedURLParams, address, bounds },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, urlQueryParams } = this.props;
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  handleSubmit( values ) {
    const { currentSearchParams } = this.props;
    const { search, selectedPlace } = values.location;
    const { history } = this.props;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      bounds,
    };

//    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, { pub_jobRoleName: search }));
    history.push(createResourceLocatorString('JobRoleSearchPage', routeConfiguration(), { 'jobRole': selectedPlace.key }, {}));
  };

  render() {
    const {
      className,
      rootClassName,
      intl,
      urlQueryParams,
      currentUser,
      listings,
      jobRoleOption,
      searchInProgress,
      searchListingsError,
      searchParamsAreInSync,
      onActivateListing,
      onManageDisableScrolling,
      onOpenModal,
      onCloseModal,
      onMapIconClick,
      pagination,
      searchParamsForPagination,
      showAsModalMaxWidth,
      filterConfig,
      sortConfig,
      sendEnquiryInProgress,
      sendEnquirySuccess,
      sendEnquiryError,
      onSendEnquiry,
      history,
      addToFavorites,
      removeFromFavorites,
      location
    } = this.props;

    const primaryFilters = filterConfig.filter(f => f.group === 'primary');
    const secondaryFilters = filterConfig.filter(f => f.group !== 'primary');
    const hasSecondaryFilters = !!(secondaryFilters && secondaryFilters.length > 0);

    // Selected aka active filters
    const selectedFilters = validFilterParams(urlQueryParams, filterConfig);
    const selectedFiltersCount = Object.keys(selectedFilters).length;

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(urlQueryParams, secondaryFilters)
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;

    const isSecondaryFiltersOpen = !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
          isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
          toggleSecondaryFiltersOpen: isOpen => {
            this.setState({ isSecondaryFiltersOpen: isOpen });
          },
          selectedSecondaryFiltersCount,
        }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : 0;
    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync && hasPaginationInfo;

    const sortBy = mode => {
      const conflictingFilterActive = isAnyFilterActive(
        sortConfig.conflictingFilters,
        urlQueryParams,
        filterConfig
      );

      const mobileClassesMaybe =
        mode === 'mobile'
          ? {
              rootClassName: css.sortBy,
              menuLabelRootClassName: css.sortByMenuLabel,
            }
          : {};
      return sortConfig.active ? (
        <SortBy
          {...mobileClassesMaybe}
          sort={urlQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          onSelect={this.handleSortBy}
          showAsPopup
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };

    const cardViewToggleLine = (
      <div className={css.cardViewToggleLine}>
        <img
          src={
            this.state.viewType === VIEW_TYPE_BAND ?
              viewSelectedListImage :
              viewUnselectedListImage
          }
          className={css.cardViewToggleIcon}
          onClick={() => this.setViewType( VIEW_TYPE_BAND )}
        />
        <img
          src={
            this.state.viewType === VIEW_TYPE_CARD ?
              viewSelectedCardImage :
              viewUnselectedCardImage
          }
          className={css.cardViewToggleIcon}
          onClick={() => this.setViewType( VIEW_TYPE_CARD )}
        />
      </div>
    );

    const resetFiltersMessage = intl.formatMessage({ id: 'SearchFiltersPrimary.resetAllFilters' });
    const searchForm = (
      <div className={css.filtersLine1}>
        <JobRoleFilterSearchForm
          className={css.searchLink}
          desktopInputRoot={css.topbarSearchWithLeftPadding}
          onSubmit={this.handleSubmit}
          initialValues={{ location: null }}
        />
        <Button
          onClick={this.resetAll}
          rootClassName={css.closeMobile}
          title={resetFiltersMessage}
        >
          <IconClose rootClassName={css.closeIcon} />
        </Button>
      </div>
    );

    const primaryFiltersLine2 = (
      <div className={css.filtersLine2}>
        {primaryFilters.map(( config, index ) => {
          return index < 2 ? (
            <FilterComponent
              key={`SearchFiltersPrimary.${config.id}`}
              idPrefix="SearchFiltersPrimary"
              filterConfig={config}
              urlQueryParams={urlQueryParams}
              initialValues={this.initialValues}
              getHandleChangedValueFn={this.getHandleChangedValueFn}
              showAsPopup
              contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
            />
          ) : null;
        })}
      </div>
    );

    const primaryFiltersLine3 = (
      <div className={css.filtersLine3}>
        {primaryFilters.map(( config, index ) => {
          return index >= 2 ? (
            <FilterComponent
              key={`SearchFiltersPrimary.${config.id}`}
              idPrefix="SearchFiltersPrimary"
              filterConfig={config}
              urlQueryParams={urlQueryParams}
              initialValues={this.initialValues}
              getHandleChangedValueFn={this.getHandleChangedValueFn}
              showAsPopup
              contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
            />
          ) : null;
        })}
      </div>
    );

    const classes = classNames(rootClassName || css.searchResultContainer, className);

    return (
      <div className={classes}>
        <SearchFiltersPrimary
          className={css.searchFiltersPrimary}
          sortByComponent={sortBy('desktop')}
          listingsAreLoaded={listingsAreLoaded}
          resultsCount={totalItems}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          jobRoleOption={jobRoleOption}
          {...propsForSecondaryFiltersToggle}
        >
          {searchForm}
          {primaryFiltersLine2}
          {primaryFiltersLine3}
          <Button
            onClick={this.resetAll}
            rootClassName={css.closeDesktop}
            title={resetFiltersMessage}
          >
            <IconClose rootClassName={css.closeIcon} />
          </Button>
        </SearchFiltersPrimary>
        {isSecondaryFiltersOpen ? (
          <div className={classNames(css.searchFiltersPanel)}>
            <SearchFiltersSecondary
              urlQueryParams={urlQueryParams}
              listingsAreLoaded={listingsAreLoaded}
              applyFilters={this.applyFilters}
              cancelFilters={this.cancelFilters}
              resetAll={this.resetAll}
              onClosePanel={() => this.setState({ isSecondaryFiltersOpen: false })}
            >
              {secondaryFilters.map(config => {
                return (
                  <FilterComponent
                    key={`SearchFiltersSecondary.${config.id}`}
                    idPrefix="SearchFiltersSecondary"
                    filterConfig={config}
                    urlQueryParams={urlQueryParams}
                    initialValues={this.initialValues}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    showAsPopup={false}
                  />
                );
              })}
            </SearchFiltersSecondary>
          </div>
        ) : (
          <div
            className={classNames(css.listings, {
              [css.newSearchInProgress]: !listingsAreLoaded,
            })}
          >
            {searchListingsError ? (
              <h2 className={css.error}>
                <FormattedMessage id="SearchPage.searchError" />
              </h2>
            ) : null}
            {cardViewToggleLine}
            <SearchRequestClause
              className={css.searchRequestClause}
              listingsAreLoaded={listingsAreLoaded}
              resultsCount={totalItems}
              sendEnquiryInProgress={sendEnquiryInProgress}
              sendEnquirySuccess={sendEnquirySuccess}
              sendEnquiryError={sendEnquiryError}
              onSendEnquiry={onSendEnquiry}
            />
            { urlQueryParams && Object.keys( urlQueryParams ).length > 0 || jobRoleOption ? (
              <SearchResultsPanel
                className={css.searchListingsPanel}
                currentUser={currentUser}
                listings={listings}
                pagination={listingsAreLoaded ? pagination : null}
                search={searchParamsForPagination}
                history={history}
                setActiveListing={onActivateListing}
                viewType={this.state.viewType}
                addToFavorites={addToFavorites}
                removeFromFavorites={removeFromFavorites}
                urlQueryParams={urlQueryParams}
                location={location}
              />
            ) : null }
          </div>
        )}
      </div>
    );
  }
}

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,

  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  onMapIconClick: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  showAsModalMaxWidth: number.isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default MainPanel;
