import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  ListingCard,
  ListingBand,
  PaginationLinks,
  Accordion,
} from '../../components';
import { VIEW_TYPE_CARD, VIEW_TYPE_BAND } from '../../containers/SearchPage/MainPanel';
import css from './SearchResultsPanel.module.css';

const SearchResultsPanel = props => {
  const {
    className, rootClassName, currentUser, listings, history, pagination, search,
    viewType, setActiveListing, addToFavorites, removeFromFavorites, urlQueryParams, location
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const favoriteListingIds =
    currentUser && Array.isArray(currentUser.attributes.profile.publicData.favoriteListingIds) ?
      currentUser.attributes.profile.publicData.favoriteListingIds : [];

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const cardsOrBands = viewType === VIEW_TYPE_CARD ? (
    <div className={css.listingCards}>
      {listings.map(l => (
        <ListingCard
          className={css.listingCard}
          key={l.id.uuid}
          listing={l}
          renderSizes={cardRenderSizes}
          setActiveListing={setActiveListing}
          from="search"
          history={history}
          currentUser={currentUser}
          isFavorite={favoriteListingIds.includes(l.id.uuid)}
          addToFavorites={addToFavorites}
          removeFromFavorites={removeFromFavorites}
        />
      ))}
      {props.children}
    </div>
  ) : (
    <div className={css.listingBands}>
      {listings.map(l => (
        <ListingBand
          className={css.listingBand}
          key={l.id.uuid}
          listing={l}
          renderSizes={cardRenderSizes}
          setActiveListing={setActiveListing}
          from="search"
          history={history}
          currentUser={currentUser}
          isFavorite={favoriteListingIds.includes(l.id.uuid)}
          addToFavorites={addToFavorites}
          removeFromFavorites={removeFromFavorites}
        />
      ))}
      {props.children}
    </div>
  );

  return (
    <div className={classes}>
      {cardsOrBands}
      {paginationLinks}

      {<Accordion
        urlQueryParams={urlQueryParams}
        location={location}
      />}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
